<template>
  <a-card class="card" :bordered="false">
    <a-tabs :active-key="mainTabKey" @change="handleTabChange">
      <a-tab-pane tab="开票审核" key="charge">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="流水号">
                  <a-input v-model.trim="queryParam1.record_no" placeholder="根据流水号搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="部门">
                  <a-select
                    showSearch
                    placeholder="请选择部门"
                    style="width: 100%"
                    v-model="queryParam1.department"
                    @change="changeDepartment"
                  >
                    <a-select-option v-for="op in GLOBAL.finDepartment" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="客户名称">
                  <a-select
                    showSearch
                    label-in-value
                    allowClear
                    placeholder="请选择委托客户"
                    style="width: 100%"
                    :value="customerInfo"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="getDepartmentCustomer"
                    @change="changeCustomerInfo"
                  >
                    <template v-if="fetching" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                    <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="申请时间">
                  <a-range-picker @change="onDateChange('queryParam1', ...arguments)" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="状态">
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择状态"
                    style="width: 100%"
                    v-model="queryParam1.status"
                    @change="changeFeeStatus"
                  >
                    <a-select-option v-for="item in GLOBAL.finFeeStatusMaps" :key="item.value">{{
                      item.name
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="业务编号">
                  <a-input v-model.trim="queryParam1.business_no" placeholder="根据业务编号搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="发票号">
                  <a-input v-model.trim="queryParam1.invoice_no" placeholder="根据发票号搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam1.department === 1">
                <a-form-item label="业务类型">
                  <a-select
                    allowClear
                    placeholder="请选择业务类型"
                    style="width: 100%"
                    v-model="queryParam1.order_type"
                    @change="changeOrderType"
                  >
                    <a-select-option v-for="op in wmsOrderTypeOps" :key="op.value">{{ op.name }} </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam1.department === 1">
                <a-form-item label="结算方式">
                  <a-select
                    allowClear
                    placeholder="请选择结算方式"
                    style="width: 100%"
                    v-model="queryParam1.charge_type"
                  >
                    <a-select-option v-for="op in wmsChargeTypeOps" :key="op.value">{{ op.name }} </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam1.department === 1 || queryParam1.department === 8">
                <a-form-item label="现金交易号">
                  <a-input v-model.trim="queryParam1.trx_num" placeholder="根据现金交易号搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam1.department === 1 || queryParam1.department === 8">
                <a-form-item label="现金交易时间">
                  <a-range-picker @change="onPayDateChange" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="开票时间">
                  <a-range-picker @change="onDateChange2('queryParam1', ...arguments)" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam1.department === 2">
                <a-form-model-item label="订单类型">
                  <a-select
                    allowClear
                    placeholder="请选择订单类型"
                    style="width: 100%"
                    v-model="queryParam1['order_type']"
                    @change="changeFeeStatus"
                  >
                    <a-select-option v-for="op in orderTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <!-- 堆场部门显示 -->
              <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam1.department === 6">
                <a-form-model-item label="堆场类型">
                  <a-select
                    allowClear
                    placeholder="请选择堆场类型"
                    style="width: 100%"
                    v-model="queryParam1.group_id"
                  >
                    <a-select-option v-for="op in groupIdOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam1.department === 6">
                <a-form-model-item label="是否扫码开票">
                  <a-select
                    allowClear
                    placeholder="请选择是否扫码开票"
                    style="width: 100%"
                    v-model="queryParam1.scan_invoice"
                  >
                    <a-select-option v-for="op in scanInvoiceOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam1['department'] !== 1">
                <a-form-item label="资金是否到账">
                  <a-select
                    v-model="queryParam1['received']"
                    placeholder="请选择资金是否到账"
                    style="width: 100%"
                    allowClear
                    @change="$refs.table1.refresh(true)"
                  >
                    <a-select-option v-for="op in receivedOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="action-button">
          <a-button type="primary" @click="$refs.table1.refresh(true)">查询</a-button>
          <a-button type="primary" @click="handleOrderPass" :disabled="!selectedRowKeys.length">审核通过</a-button>
          <a-button v-if="queryParam1.department === 1" type="primary" @click="storeInvoice">现场结费</a-button>
          <a-button v-if="queryParam1.department === 6" type="primary" @click="ymsStoreInvoice">登记开票</a-button> <!-- 堆场相关登记开票功能 -->
          <a-button v-if="queryParam1.department === 8" type="primary" icon="download" @click="tradeDebtSheet">贸易应收表</a-button>
          <a-button v-if="queryParam1.department === 8" type="primary" icon="download" @click="tradeNoInvoicedSheet">贸易预收表</a-button>
          <a-popover
            v-model="popVisible"
            title="选择日期"
            trigger="click">
            <a slot="content">
              <p><a-date-picker style="width: 200px" @change="onDateSingleChange" /></p>
              <p style="text-align: right"><a-button type="primary" @click="exportWmsPosInvoiceSettlementSheet">确定</a-button></p>
            </a>
            <a-button v-if="queryParam1.department === 1" type="primary" icon="download">POS机发票结款清单</a-button>
          </a-popover>
          <a-popover
            v-model="popVisible2"
            title="选择日期"
            trigger="click">
            <a slot="content">
              <p><a-date-picker style="width: 200px" @change="onDateSingleChange" /></p>
              <p style="text-align: right"><a-button type="primary" @click="exportPayIncomeTotalSheet">确定</a-button></p>
            </a>
            <a-button v-if="queryParam1.department === 1" type="primary" icon="download">通联收入汇总</a-button>
          </a-popover>
          <a-popover
            v-model="popVisible3"
            title="选择日期"
            trigger="click">
            <a slot="content">
              <p><a-range-picker style="width: 200px" @change="onDateRangeChange" /></p>
              <p style="text-align: right"><a-button type="primary" @click="exportWmsChargeTotalSheet">确定</a-button></p>
            </a>
            <a-button v-if="queryParam1.department === 1" type="primary" icon="download">仓储现金收费清单</a-button>
          </a-popover>
          <a-popover
            v-model="popVisible4"
            title="选择日期"
            trigger="click">
            <a slot="content">
              <p><a-range-picker style="width: 200px" @change="ymsInvoiceDetailDateChange" /></p>
              <p style="text-align: right"><a-button type="primary" @click="exportYmsInvoiceDetailSheet">确定</a-button></p>
            </a>
            <a-button v-if="queryParam1.department === 6" type="primary" icon="download">开票明细导出</a-button>
          </a-popover>
          <a-popover
            v-model="popVisible5"
            title="选择日期"
            trigger="click">
            <a slot="content">
              <p><a-month-picker style="width: 200px" @change="ymsMonthChargePayDateChange"/></p>
              <p style="text-align: right"><a-button type="primary" @click="exportYmsMonthChargePaySheet">确定</a-button></p>
            </a>
            <a-button v-if="queryParam1.department === 6" type="primary" icon="download">应收应付总表导出</a-button>
          </a-popover>
        </div>
        <s-table
          ref="table1"
          size="small"
          rowKey="id"
          dynamicColumns
          :scroll="{ x: 'max-content' }"
          :columns="chargeColumns"
          :alert="{
            show: true,
            msg: showTotal+yardScanInvoiceWarning,
            clear: true
          }"
          :data="loadData1"
          :rowSelection="{
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: this.selectItem,
            onSelectAll: this.selectAllItem
          }"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
          <div slot="orderNumList" slot-scope="text, record" v-if="text">
            <a-popover placement="bottom">
              {{ text[0] }} {{ text.length !== 1 ? `(${text.length}单)` : null }}
              <a-tag color="red" v-if="record.fee_complete===0">费用未完整</a-tag>
              <template slot="content">
                <span style="display:block" v-for="item in text" :key="item">{{ item }}</span>
              </template>
            </a-popover>
          </div>
          <div slot="invoiceInfo" slot-scope="text">
            <a-popover placement="bottom">
              {{ text.customer_name }}
              <template slot="content">
                <span style="display:block">
                  客户名称:{{ text.customer_name }}
                </span>
                <span style="display:block">
                  纳税人识别号:{{ text.tax_no }}
                </span>
                <span style="display:block">
                  地址:{{ text.address }}
                </span>
                <span style="display:block">
                  电话:{{ text.mobile }}
                </span>
                <span style="display:block">
                  银行卡号:{{ text.bank_card_no }}
                </span>
                <span style="display:block">
                  开户银行:{{ text.bank }}
                </span>
              </template>
            </a-popover>
          </div>
          <div slot="invoiceDetail" slot-scope="text,record">
            <span style="display:block" v-for="item in text" :key="item.invoice_no">
              {{ item.invoice_no }}<a-tag color="red" v-if="item.void_status === 1">作废</a-tag>{{record.scan_invoice&&record.scan_invoice==1?"/开票金额:"+item.tax_price_total:""}}/税额:{{ item.tax }}/创建日:{{ moment(item.created_at).format('YYYY/MM/DD') }}
            </span>
          </div>
          <div slot="cashRecordList" slot-scope="text">
            <a-popover placement="bottom">
              <template v-if="text && text.length > 0">
                <span v-if="text[0].cash_status === 0">
                  <a-tag color="#1890ff" style="margin-bottom:2px;">待支付</a-tag>
                  {{ `金额:${text[0].amount}` }}
                </span>
                <span v-if="text[0].cash_status === 1">
                  <a-tag color="#4cd964" style="margin-bottom:2px;">支付完成</a-tag>
                  {{ `金额:${text[0].amount} 交易号:${text[0].trx_id} 交易时间:${text[0].pay_date}` }}
                </span>
                <span v-if="text[0].cash_status === 2">
                  <a-tag color="#faad14" style="margin-bottom:2px;">支付中</a-tag>
                  {{ `金额:${text[0].amount} 交易号:${text[0].trx_id}` }}
                </span>
                <span v-if="text[0].cash_status === 3">
                  <a-tag color="#ff4d4f" style="margin-bottom:2px;">支付失败</a-tag>
                  {{ `金额:${text[0].amount} 交易号:${text[0].trx_id}` }}
                </span>
                {{ text.length !== 1 ? `(${text.length})单` : null }}
              </template>
              <template slot="content">
                <span style="display:block" v-for="item in text" :key="item.id">
                  <span v-if="item.cash_status === 0">
                    <a-tag color="#1890ff" style="margin-bottom:2px;">
                      待支付
                    </a-tag>
                    {{ `金额:${item.amount}` }}
                  </span>
                  <span v-if="item.cash_status === 1">
                    <a-tag color="#4cd964" style="margin-bottom:2px;">
                      支付完成
                    </a-tag>
                    {{ `金额:${item.amount} 交易号:${item.trx_id} 交易时间:${item.pay_date}` }}
                  </span>
                  <span v-if="item.cash_status === 2">
                    <a-tag color="#faad14" style="margin-bottom:2px;">
                      支付中
                    </a-tag>
                    {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
                  </span>
                  <span v-if="item.cash_status === 3">
                    <a-tag color="#ff4d4f" style="margin-bottom:2px;">
                      支付失败
                    </a-tag>
                    {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
                  </span>
                </span>
              </template>
            </a-popover>
          </div>
          <div slot="otherInfo" slot-scope="text, record">
            <span v-if="record.department === 1">结算方式：{{ record.charge_type_name }}</span>
            <span v-if="record.department === 2">开票类型：{{ invoiceTypeOps[record.invoice_type].name }}</span>
            <span v-if="record.department === 7">开票类型：{{ invoiceTypeOps[record.invoice_type].name }}</span>
            <span v-if="record.department === 8">开票类型：{{ invoiceTypeOps[record.invoice_type].name }}</span>
          </div>
          <span slot="action" slot-scope="text, record, index">
            <template>
              <!-- <a v-if="record.department === 1" @click="tempDropBoxSheet">开票通知单</a> -->
              <a v-if="record.department === 1 && record.ticket_order_status === 1" style="margin-left:15px" @click="anewChargeModal(record)">收费</a>
              <a v-if="record.department === 2 || record.department === 7" @click="printDebitSheet(record)">结算单</a>
              <a v-if="record.department === 3" @click="printInvoiceNotice(record)" :style="{color:record.is_print===1?'red':'green'}">开票通知单</a>
              <a v-if="record.department === 8" @click="printInvoiceSheet(record)" :style="{color:record.is_print===1?'red':'green'}">开票通知单</a>
              <a v-if="record.department === 6 && record.scan_invoice === 0" @click="printYmsBalanceNoticeSheet(record)" :style="{color:record.is_print===1?'red':'green'}">开票通知单</a>
              <a @click="handleApplyInvoice(record)" style="margin-left:15px" v-if="record.status === 5 || record.status === 3" v-perms="'FMS_INVOICE_OPEN'">开票</a>
              <a @click="handleOrderReject(record, index)" style="margin-left:15px">驳回</a>
              <a @click="editInvoiceRecord(record)" style="margin-left:15px">发票信息</a>
              <!-- 堆场部门支持删除财商流水信息 -->
              <a v-if="record.department === 6 && record.status === 4" style="margin-left: 15px" @click="removeFmsBusinessRecord(record)">删除</a>
            </template>
          </span>
        </s-table>
      </a-tab-pane>
      <a-tab-pane tab="付款审核" key="pay" forceRender>
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="GLOBAL.queryRowGutter">
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="流水号">
                  <a-input v-model.trim="queryParam2['record_no']" placeholder="根据流水号搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="部门">
                  <a-select
                    showSearch
                    placeholder="请选择部门"
                    style="width: 100%"
                    v-model="queryParam2.department"
                    @change="changeDepartment"
                  >
                    <a-select-option v-for="op in GLOBAL.finPayDepartment" :key="op.value">{{
                      op.name
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="申请时间">
                  <a-range-picker @change="onDateChange('queryParam2', ...arguments)" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="状态">
                  <a-select
                    showSearch
                    allowClear
                    placeholder="请选择状态"
                    style="width: 100%"
                    v-model="queryParam2.status"
                    @change="changeFeeStatus"
                  >
                    <a-select-option v-for="item in GLOBAL.finPayFeeStatusMaps" :key="item.value">{{
                      item.name
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="业务编号">
                  <a-input v-model.trim="queryParam2.business_no" placeholder="根据业务编号搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-model-item label="供应商" prop="customer_name">
                  <a-select
                    showSearch
                    allowClear
                    :filter-option="filterOption"
                    ref="supplier"
                    placeholder="选择供应商"
                    v-model="queryParam2.customer_name"
                  >
                    <a-select-option v-for="d in supplierOps" :key="d.value">{{ d.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="发票号">
                  <a-input v-model.trim="queryParam2.invoice_no" placeholder="根据发票号搜索" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan">
                <a-form-item label="开票时间">
                  <a-range-picker @change="onDateChange2('queryParam2', ...arguments)" />
                </a-form-item>
              </a-col>
              <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam2.department === 2">
                <a-form-model-item label="订单类型">
                  <a-select
                    allowClear
                    placeholder="请选择订单类型"
                    style="width: 100%"
                    v-model="queryParam2['order_type']"
                    @change="changeFeeStatus"
                  >
                    <a-select-option v-for="op in orderTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <!-- 堆场部门显示 -->
              <a-col v-bind="GLOBAL.queryColSpan" v-if="queryParam2.department === 6">
                <a-form-model-item label="堆场类型">
                  <a-select
                    allowClear
                    placeholder="请选择堆场类型"
                    style="width: 100%"
                    v-model="queryParam2.group_id"
                  >
                    <a-select-option v-for="op in groupIdOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="action-button">
          <a-button type="primary" @click="$refs.table2.refresh(true)">查询</a-button>
          <a-button type="primary" @click="YmsHandleOrderPass" :disabled="!selectedRowKeys.length" v-if="queryParam2.department === 6">审核通过</a-button>
          <a-button type="primary" @click="handleOrderPass" :disabled="!selectedRowKeys.length" v-else>审核通过</a-button>
          <a-button v-if="queryParam2.department === 8" type="primary" icon="download" @click="tradeNoPaymentSheet">贸易应付表</a-button>
        </div>
        <s-table
          ref="table2"
          size="small"
          dynamicColumns
          rowKey="id"
          :scroll="{ x: 'max-content' }"
          :columns="payColumns"
          :alert="{
            show: true,
            msg: showTotal,
            clear: true
          }"
          :data="loadData2"
          :rowSelection="{
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange,
            onSelect: this.selectItem,
            onSelectAll: this.selectAllItem
          }"
        >
          <span slot="serial" slot-scope="text, record, index">
            {{ index + 1 }}
          </span>
          <div slot="orderNumList" slot-scope="text" v-if="text">
            <a-popover placement="bottom">
              {{ text[0] }} {{ text.length !== 1 ? `(${text.length}单)` : null }}
              <template slot="content">
                <span style="display:block" v-for="item in text" :key="item">{{ item }}</span>
              </template>
            </a-popover>
          </div>
          <div slot="invoiceDetail" slot-scope="text">
            <span style="display:block" v-for="item in text" :key="item.invoice_no">
              {{ item.invoice_no }}<a-tag color="red" v-if="item.void_status === 1">作废</a-tag>/税额:{{ item.tax }}/创建日:{{ moment(item.created_at).format('YYYY/MM/DD') }}
            </span>
          </div>
          <span slot="action" slot-scope="text, record, index">
            <template>
              <a v-if="record.department === 2 || record.department === 3 || record.department === 7 || record.department === 6 || record.department === 9" @click="handlePrint(record)">付款单</a>
              <a v-if="record.department === 8" @click="printPaymentSheet(record)">付款单</a>
              <a @click="handleOrderReject(record, index)" style="margin-left:15px">驳回</a>
              <a v-if="record.department === 6 && record.order_fee_class_list.includes(204)" style="margin-left:15px" @click="listYmsPayOrderFeeInfoRelation(record)">退移箱费</a>
            </template>
          </span>
          <template slot="costDateSlot" slot-scope="text, record">
            <a @click="openUpdateCostDate(record)" v-if="record.cost_begin_date && record.cost_end_date">
              {{ record.cost_begin_date }} / {{ record.cost_end_date }}
            </a>
          </template>
        </s-table>
      </a-tab-pane>
    </a-tabs>
    <!-- 驳回 -->
    <a-modal
      :visible="rejectFormVisible"
      :width="720"
      title="驳回详情"
      :maskClosable="false"
      @cancel="rejectFormVisible = false"
      @ok="handleRejectFormSubmit"
    >
      <a-form-model layout="vertical" :model="rejectForm" :rules="rejectRules" ref="rejectForm">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="流水号">
              <a-input v-model.trim="currentData.record_no" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="所属客户">
              <a-input v-model.trim="currentData.customer" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="申请人">
              <a-input v-model.trim="currentData.applicant_name" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="申请备注">
              <a-textarea v-model.trim="currentData.apply_remark" disabled></a-textarea>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="驳回原因" prop="deny_reason">
              <a-textarea v-model.trim="rejectForm.deny_reason"></a-textarea>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>

    <a-modal
      :visible="storeInvoiceVisible"
      title="现场结费"
      :width="1500"
      :maskClosable="false"
      @cancel="storeInvoiceClose"
      :footer="false"
    >
      <a-steps :current="scanQRCodeStatus">
        <a-step title="扫结费码" description="扫码获取结费单上的订单信息">
        </a-step>
        <a-step title="填写发票信息" description="发票抬头，税率为必填项">
        </a-step>
        <a-step title="扫码收款" :description="`需收款合计:${storeSceneTotal}`">
        </a-step>
      </a-steps>
      <div class="space-between">
        <a-button :disabled="scanQRCodeStatus === 0" @click="prev">
          上一步
        </a-button>
        <a-button :disabled="scanQRCodeStatus === 2" type="primary" @click="next">
          下一步
        </a-button>
      </div>
      <a-form-model style="margin:30px 0 0" layout="vertical" :model="sceneForm" :rules="sceneRules" ref="sceneForm">
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="发票抬头" prop="customer_name">
              <a-select
                :disabled="scanQRCodeStatus !== 1"
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo2"
                :filter-option="false"
                :not-found-content="fetching2 ? undefined : null"
                @search="getDepartmentCustomer2"
                @change="changeCustomerInfo2"
              >
                <template v-if="fetching2" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in sceneCustomer" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="税率" prop="tax_rate">
              <a-select v-model="sceneForm.tax_rate" :disabled="scanQRCodeStatus !== 1">
                <a-select-option :value="item.value" v-for="item in taxRateOps" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div v-if="scanQRCodeStatus === 0">
        <a-input ref="storeInvoiceInput" placeholder="请扫描结款小票二维码" v-model="storeInvoiceInfo" @keyup.enter.native="getStoreInvoiceInfo"></a-input>
      </div>
      <div v-if="scanQRCodeStatus === 2">
        <a-radio-group v-model="sceneChargeType" @change="changeChargeType">
          <a-radio :value="1">
            扫码枪收款
          </a-radio>
          <a-radio :value="2">
            POS机收款
          </a-radio>
        </a-radio-group>
        <div v-if="sceneChargeType === 1" style="margin-top: 10px">
          <a-input ref="payInfoInput" placeholder="请扫描付款码" v-model="payInfo" @keyup.enter.native="chargeFee"></a-input>
        </div>
        <div v-if="sceneChargeType === 2" style="margin-top: 10px">
          <a-input placeholder="请输入POS机小票交易号" v-model="posChargeTrxId"></a-input>
          <div class="space-between">
            <div></div>
            <a-button type="primary" @click="chargeByPos" style="margin-top: 5px">Pos机已收费</a-button>
          </div>
        </div>
      </div>
      <a-alert style="margin: 20px 0" :message="storeSceneMessage" type="success" />
      <a-table
        rowKey="record_id"
        :columns="queryParam1.order_type === 1 ? storeScene : storeDropScene"
        :data-source="storeSceneList"
        :pagination="false"
      >
        <span slot="serial" slot-scope="text, record, index">{{ (index + 1) }}</span>
        <div slot="fee_info" slot-scope="text">
          <span style="display: block" v-for="item in text" :key="item.id">{{ item.fee_name }}:{{ item.amount }}</span>
        </div>
        <div slot="cashRecordList" slot-scope="text">
          <span style="display:block" v-for="item in text" :key="item.id">
            <span v-if="item.cash_status === 0">
              <a-tag color="#1890ff" style="margin-bottom:2px;">
                待支付
              </a-tag>
              {{ `金额:${item.amount}` }}
            </span>
            <span v-if="item.cash_status === 1">
              <a-tag color="#4cd964" style="margin-bottom:2px;">
                支付完成
              </a-tag>
              {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
            </span>
            <span v-if="item.cash_status === 2">
              <a-tag color="#faad14" style="margin-bottom:2px;">
                支付中
              </a-tag>
              {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
            </span>
            <span v-if="item.cash_status === 3">
              <a-tag color="#ff4d4f" style="margin-bottom:2px;">
                支付失败
              </a-tag>
              {{ `金额:${item.amount} 交易号:${item.trx_id}` }}
            </span>
          </span>
        </div>
        <div slot="action" slot-scope="text, record">
          <a @click="deleteInvoice(record)">删除</a>
        </div>
      </a-table>
    </a-modal>

    <a-modal
      :visible="anewChargeVisible"
      :loading="anewChargeLoading"
      title="收款"
      :width="500"
      :maskClosable="false"
      @cancel="anewChargeVisible = false"
      :footer="false"
    >
      <span>请扫付款码</span>
      <a-input ref="anewPayInfoInput" v-model="anewPayInfo" @keyup.enter.native="anewChargeFee"></a-input>
    </a-modal>

    <!-- 堆场现场结费 -->
    <a-modal
      :visible="ysmStoreInvoiceVisible"
      title="登记开票"
      :width="1200"
      :maskClosable="false"
      @cancel="ysmStoreInvoiceClose"
    >
      <a-steps :current="ymsScanQRCodeStatus">
        <a-step title="填写发票信息" description="发票抬头，税率为必填项">
        </a-step>
        <a-step title="扫结费码" description="扫码获取结费单上的订单信息">
        </a-step>
      </a-steps>

      <div class="space-between">
        <a-button :disabled="ymsScanQRCodeStatus === 0" @click="ymsPrev">上一步</a-button>
        <a-button :disabled="ymsScanQRCodeStatus === 1" type="primary" @click="ymsNext">下一步</a-button>
      </div>

      <a-form-model
        style="margin:30px 0 0;"
        layout="vertical"
        :model="ymsSceneForm"
        :rules="ymsSceneRules"
        ref="ymsSceneForm"
      >
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="发票抬头" prop="customer_name">
              <a-select
                :disabled="ymsScanQRCodeStatus !== 0"
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo2"
                :filter-option="false"
                :not-found-content="fetching2 ? undefined : null"
                @search="getDepartmentCustomer2"
                @change="changeCustomerInfo2"
              >
                <template v-if="fetching2" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in sceneCustomer" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="12">
            <a-form-model-item label="税率" prop="tax_rate">
              <a-select v-model="ymsSceneForm.tax_rate" :disabled="ymsScanQRCodeStatus !== 0">
                <a-select-option :value="item.value" v-for="item in taxRateOps" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>

      <!-- class="scan-input" -->
      <a-input v-if="ymsScanQRCodeStatus === 1" ref="ymsStoreInvoiceInput" @keyup.enter.native="ymsGetStoreInvoiceInfo"></a-input>

      <a-alert style="margin: 20px 0" :message="ymsStoreMsg" type="success" />

      <a-table
        rowKey="record_id"
        :loading="ymsStoreLoading"
        :columns="ymsStoreScene"
        :data-source="ymsStoreSceneList"
        :pagination="false"
      >
        <span slot="serial" slot-scope="text, record, index">{{ (index + 1) }}</span>
        <div slot="fee_info" slot-scope="text">
          <span style="display: block;" v-for="(item, index) in text" :key="index">{{ item.cost_name }}:{{ item.amount }}</span>
        </div>
        <div slot="action" slot-scope="text, record">
          <a @click="ymsDeleteInvoice(record)">删除</a>
        </div>
      </a-table>

      <template slot="footer">
        <a-button type="primary" @click="createInvoiceRecord">确认开票</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printPayBillModel"
      :confirmLoading="printPayBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款单"
      @cancel="printPayBillModel = false"
    >
      <div id="printPayBill">
        <fms-pay-bill
          :orderList="printPayBillResult"
          :orderData="printPayBillRecord"
          :invoiceList="invoiceList"
        >
        </fms-pay-bill>
      </div>
      <template slot="footer">
        <a-button id="printPayBillBtn" key="submit" type="primary" v-print="printPayBill">确认打印 </a-button>
        <a-button type="primary" @click="printPayBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printPayStatementsBillModel"
      :confirmLoading="printPayStatementsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款对账单"
      @cancel="printPayStatementsBillModel = false"
    >
      <div id="printStatementsPayBill">
        <fms-pay-state-bill :currentData="currentData" :balanceData="balanceData"></fms-pay-state-bill>
      </div>
      <template slot="footer">
        <a-button id="printStatementsPayBillBtn" key="submit" type="primary" v-print="printStatementsPayBill">确认打印</a-button>
        <a-button type="primary" @click="printPayStatementsBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printCumsBillModel"
      :confirmLoading="printCumsBillModelLoading"
      :width="1420"
      :maskClosable="false"
      title="打印客服结算单"
      @cancel="printCumsBillModel = false"
    >
      <div id="printCumsBill">
        <cums-bill :currentData="currentData" :balanceData="balanceData"></cums-bill>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" id="printCumsBillBtn" v-print="printCumsBill">确认打印</a-button>
        <a-button type="primary" @click="printCumsBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <!-- 编辑发票信息 -->
    <a-modal
      :visible="editInvoiceRecordVisible"
      :confirmLoading="editInvoiceRecordLoading"
      :width="1000"
      :maskClosable="false"
      title="编辑发票信息"
      @cancel="cancelEditInvoiceRecord"
      @ok="handleEditInvoiceRecordSubmit"
    >
      <edit-table
        :columns="editInvoiceRecordColumns"
        :sourceData="editInvoiceRecordData"
        @getTableDate="getEditInvoiceRecordData"
        @getTableDateError="getTableDateError"
      />
    </a-modal>
    <!-- 打印发票 -->
    <a-modal
      :visible="printInvoiceVisible"
      :confirmLoading="printInvoiceLoading"
      :width="1000"
      :maskClosable="false"
      title="打印发票"
      @cancel="cancelprintInvoiceRecord"
      @ok="handlePrintInvoiceSubmit"
      okText="全部打印"
    >
      <a-table
        size="small"
        row-key="id"
        bordered
        :pagination="false"
        :scroll="{ x: 'max-content' }"
        :columns="printInvoiceColumns"
        :data-source="printInvoiceData"
      >
        <template slot="action" slot-scope="text, record">
          <a @click="handlePrintOneInvoice(record)">{{record.einvoice_url?"下载":"打印"}}</a>
        </template>
      </a-table>
      <a-form-model-item label="邮箱地址" prop="email">
        <a-select mode="tags" v-model.trim="emailForm.emails" style="width: 100%" @search="handleSearchEmails" @change="handleChangeEmail">
          <a-select-option v-for="item in invoiceEmails" :key="item">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-button type="primary" :disabled="sendEmailDisabled" icon="plus" @click="handleSendEmailSubmit">发送邮件</a-button>
    </a-modal>

    <a-modal
      :visible="printItmsBillModel"
      :confirmLoading="printItmsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印国贸结算单"
      @cancel="printItmsBillModel = false"
    >
      <div id="printItmsBill">
        <itms-bill :currentData="currentData" :balanceData="balanceData"></itms-bill>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" id="printItmsBillBtn" v-print="printItmsBill">确认打印</a-button>
        <a-button type="primary" @click="printItmsBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printInvoiceNoticeBillModel"
      :confirmLoading="printInvoiceNoticeBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印开票通知单"
      @cancel="printInvoiceNoticeBillModel = false"
    >
      <div id="printMmsInvoiceNoticeBill">
        <MmsInvoiceNoticeBill :currentData="currentData" :balanceData="detailData"></MmsInvoiceNoticeBill>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" id="printMmsInvoiceNoticeBillBtn" v-print="printMmsInvoiceNoticeBill">确认打印</a-button>
        <a-button type="primary" @click="printInvoiceNoticeBillModel = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printInvoiceSheetVisible"
      :confirmLoading="printInvoiceSheetLoading"
      :width="1520"
      :maskClosable="false"
      title="打印开票单"
      @cancel="printInvoiceSheetVisible = false"
    >
      <div id="printInvoiceSheetBill">
        <InvoiceSheetBill :currentData="currentData" :balanceData="invoiceData"></InvoiceSheetBill>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" id="printInvoiceSheetBillBtn" v-print="printInvoiceSheetBill">确认打印</a-button>
        <a-button type="primary" @click="printInvoiceSheetVisible = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printPaymentSheetVisible"
      :confirmLoading="printPaymentSheetLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款单"
      @cancel="printPaymentSheetVisible = false"
    >
      <div id="printPaymentSheetBill">
        <InvoiceSheetBill :currentData="currentData" :balanceData="invoiceData"></InvoiceSheetBill>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" id="printPaymentSheetBillBtn" v-print="printPaymentSheetBill">确认打印</a-button>
        <a-button type="primary" @click="printPaymentSheetVisible = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printTempDropBoxSheetVisible"
      :confirmLoading="printTempDropBoxSheetLoading"
      :width="1520"
      :maskClosable="false"
      title="暂落箱开票通知单"
      @cancel="printTempDropBoxSheetVisible = false"
    >
      <div id="printTempDropBoxSheetBill">
        <TempDropBoxSheetBill :currentData="currentData" :balanceData="invoiceData"></TempDropBoxSheetBill>
      </div>
      <template slot="footer">
        <a-button key="submit" type="primary" id="printTempDropBoxSheetBillBtn" v-print="printTempDropBoxSheetBill">确认打印</a-button>
        <a-button type="primary" @click="printTempDropBoxSheetVisible = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="printYmsBalanceNoticeSheetVisible"
      :width="1300"
      :maskClosable="false"
      title="打印开票通知单"
      @cancel="printYmsBalanceNoticeSheetVisible = false"
    >
      <div id="printYmsBalanceNoticeSheetBill">
        <YmsBalanceNoticeBill :dataSource="printYmsBalanceNoticeList" :dataRecord="printYmsBalanceNoticeData"></YmsBalanceNoticeBill>
      </div>
      <template slot="footer">
        <a-button id="printYmsBalanceNoticeSheetBillBtn" key="submit" type="primary" v-print="printYmsBalanceNoticeSheetBill">确认打印</a-button>
        <a-button type="primary" @click="printYmsBalanceNoticeSheetVisible = false">关闭</a-button>
      </template>
    </a-modal>

    <a-modal
      :visible="listPayOrderFeeInfoVisible"
      title="退移箱费明细"
      :width="1000"
      @cancel="listPayOrderFeeInfoCancel"
      :footer="null"
    >
      <a-table
        size="middle"
        row-key="id"
        :scroll="{ x: 'max-content' }"
        :columns="listPayOrderFeeInfoColumns"
        :data-source="listPayOrderFeeInfoData"
        :pagination="false"
      ></a-table>
    </a-modal>

    <!-- 堆场审核通过、设置成本时间 -->
    <a-modal
      :visible="ymsOrderPassVisible"
      :confirmLoading="ymsOrderPassLoading"
      :width="400"
      :maskClosable="false"
      title="堆场审核通过"
      @cancel="ymsOrderPassCancel"
      @ok="ymsOrderPassSubmit"
    >
      <a-form-model
        layout="vertical"
        ref="ymsOrderPassForm"
        :model="ymsOrderPassForm"
        :rules="ymsOrderPassRules"
      >
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item label="设置成本月份" prop="cost_date">
              <a-range-picker
                style="width: 100%"
                format="YYYY-MM"
                :mode="costMode"
                :value="costDate"
                @panelChange="handlePanelChange"
                @change="costDateChange" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>

    <!-- 修改成本时间 -->
    <a-modal
      :visible="updateCostDateVisible"
      :confirmLoading="updateCostDateLoading"
      :width="400"
      :maskClosable="false"
      title="修改成本时间"
      @cancel="updateCostDateCancel"
      @ok="updateCostDateOk"
    >
      <a-form-model
        layout="vertical"
        ref="ymsOrderPassForm"
        :model="ymsOrderPassForm"
        :rules="ymsOrderPassRules"
      >
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item label="修改成本月份" prop="cost_date">
              <a-range-picker
                style="width: 100%"
                format="YYYY-MM"
                :mode="costMode"
                :value="costDate"
                @panelChange="handlePanelChange"
                @change="costDateChange" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>

    <!-- 申请接口开票 -->
    <a-modal
      :visible="applyInvoiceVisible"
      :confirmLoading="applyInvoiceLoading"
      :width="1400"
      :maskClosable="false"
      title="开具发票"
      @cancel="applyInvoiceCancel"
      @ok="applyInvoiceOk"
    >
      <a-form-model layout="inline">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-model-item label="金额">
              <span style="font-weight:700">{{this.applyInvoiceRecord.sum_amount}}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="税率">
              <span style="font-weight:700">{{this.applyInvoiceRecord.tax_desc}}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="购买单位">
              <span style="font-weight:700">{{this.applyInvoiceRecord.customer_name}}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <a-form-model layout="inline" :model="applyInvoiceForm" :rules="applyInvoiceRules" ref="applyInvoiceForm">
        <a-row :gutter="24">
          <!-- <a-divider orientation="left" style="font-weight:700">开票类型</a-divider> -->
          <a-col :span="18">
            <a-form-model-item prop="invoice_type_code">
              <a-radio-group v-model="applyInvoiceForm.invoice_type_code">
                <!-- <a-radio-button value="004">
                  增值税专用发票
                </a-radio-button>
                <a-radio-button value="007">
                  增值税普通发票
                </a-radio-button>
                <a-radio-button value="028">
                  增值税电子专用发票
                </a-radio-button>
                <a-radio-button value="026">
                  增值税电子普通发票
                </a-radio-button> -->
                <a-radio-button value="01">
                  全电专用发票
                </a-radio-button>
                <a-radio-button value="02">
                  全电普通发票
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="many_together">
              <a-radio-group v-model="applyInvoiceForm.many_together">
                <a-radio-button value="N">
                  单票
                </a-radio-button>
                <a-radio-button value="Y">
                  多票
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="4">
            <a-form-model-item>
              <a-radio-group v-model="applyInvoiceForm.invoice_type">
                <a-radio-button value="0">
                  正数
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item>
              <a-radio-group v-model="applyInvoiceForm.taxation_method">
                <a-radio-button value="0">
                  普通征税
                </a-radio-button>
                <a-radio-button value="1">
                  差额征税
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-radio-group v-model="applyInvoiceForm.price_tax_mark" @change="calcTotalPrice(true)">
                <a-radio-button value="1">
                  含税
                </a-radio-button>
                <a-radio-button value="0">
                  不含税
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-radio-group v-model="applyInvoiceForm.invoice_list_mark">
                <a-radio-button value="0">
                  明细
                </a-radio-button>
                <a-radio-button value="1">
                  清单
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="tax_tray_id">
              <a-select
                v-model="applyInvoiceForm.tax_tray_id"
                showSearch
                allowClear
                style="width: 240px"
                @change="changeSaleInvoiceInfo"
              >
                <a-select-option v-for="op in taxtrayList" :key="op.id">{{ op.tax_tray_name+op.sale_company_tax_no }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-divider orientation="left" style="font-weight:1700">购买单位：
            <a-select
              showSearch
              label-in-value
              allowClear
              placeholder="请选择购买单位"
              style="width: 360px"
              :value="invoiceBuyerInfo"
              :filter-option="false"
              :not-found-content="buyerfetching ? undefined : null"
              @search="getInvoiceBuyerInfo"
              @change="changeInvoiceBuyerInfo"
            >
              <template v-if="buyerfetching" #notFoundContent>
                <a-spin size="small" />
              </template>
              <a-select-option v-for="op in invoiceBuyerOps" :key="op.value">{{ op.name }}</a-select-option>
            </a-select>
          </a-divider>
          <a-col :span="12">
            <a-form-model-item label="名称" prop="buyer_name">
              <a-input v-model.trim="applyInvoiceForm.buyer_name" style="width:360px"/>
              <a-tag color="red" v-if="applyInvoiceForm.is_allow_invoice&&applyInvoiceForm.is_allow_invoice === 'N'">
                不允许开票
              </a-tag>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="税号">
              <a-input v-model.trim="applyInvoiceForm.buyer_tax_no" style="width:360px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="地址">
              <a-input v-model.trim="applyInvoiceForm.buyer_address_phone" style="width:360px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="电话">
              <a-input v-model.trim="applyInvoiceForm.buyer_telphone" style="width:360px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="银行名称">
              <a-input v-model.trim="applyInvoiceForm.buyer_bank_account" style="width:360px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="银行账户">
              <a-input v-model.trim="applyInvoiceForm.buyer_account" style="width:360px"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-divider orientation="left" style="font-weight:700">开票内容<a-tag :color="applyInvoiceForm.price_tax_mark==='1'?'green':'red'">{{applyInvoiceForm.price_tax_mark==='1'?'含税(单价、金额)':'不含税(单价、金额)' }}</a-tag></a-divider>
          <a-col :span="24">
            <edit-table
              :columns="invoiceSubjectColumns"
              :sourceData="invoiceSubjectData"
              :scroll="true"
              :operate="{ add: true, del: true, alldel: false }"
              @getTableDate="getInvoiceSubjectTableDate"></edit-table>
          </a-col>
          <a-col :span="24">
            <span style="font-weight:700;color:red">含税总额：{{ this.totalInvoiceAmount }}</span>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <!-- <a-divider orientation="left" style="font-weight:700">销货单位</a-divider> -->
          <a-col :span="12">
            <a-form-model-item label="名称" prop="sale_company_name">
              <a-input v-model="applyInvoiceForm.sale_company_name" style="width:360px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="税号" prop="sale_company_tax_no">
              <a-input v-model="applyInvoiceForm.sale_company_tax_no" style="width:360px"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="地址电话" prop="sale_company_address_mobile">
              <a-input v-model="applyInvoiceForm.sale_company_address_mobile" style="width:360px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="银行账户" prop="sale_company_bank_account">
              <a-input v-model="applyInvoiceForm.sale_company_bank_account" style="width:360px"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="票面备注">
              <a-textarea v-model="applyInvoiceForm.remarks" style="width:360px" :rows="3"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="收款人" prop="payee">
              <a-input v-model="applyInvoiceForm.payee" />
            </a-form-model-item>
            <a-form-model-item label="复核人" prop="checker">
              <a-input v-model="applyInvoiceForm.checker" />
            </a-form-model-item>
            <a-form-model-item label="开票人" prop="drawer">
              <a-input v-model="applyInvoiceForm.drawer" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable, EditTable } from '@/components'
import moment from 'moment'
import { getRandomCode, filterOption, numberToChinese } from '@/utils/util'
import {
  getBizAuditPage,
  approveFmsInfo,
  checkBeforeApprove,
  denyFmsInfo,
  getFmsInfoDetail,
  getInvoiceDataByAuditId,
  getChargeQrCode,
  getInvoiceRecordByAuditRecordId,
  fmsSaveInvoice,
  getInvoiceSheetBill,
  getTempDropBoxSheetBill,
  getMmsSheetBill,
  getTaxRateById,
  scanQRCodeCharge,
  posCharge,
  anewScanQRCodeCharge,
  getWmsRecord,
  getchargeTax,
  getTicketInfo,
  createInvoiceRecord,
  exportWmsPosInvoiceSettlementSheet,
  exportPayIncomeTotalSheet,
  exportChargeTotalSheet,
  removeFmsBusinessRecord,
  getBalanceNoticeSheet,
  exportYmsInvoiceDetailSheet,
  listYmsPayOrderFeeInfoRelation,
  exportYmsMonthChargePaySheet,
  updateCostDate,
  queryRecordInvoiceApplyInfo,
  listSecondInvoiceSubject,
  submitApplyInvoiceForm,
  printInvoiceByAuditId,
  printInvoice,
  getEInvoiceAndSendEmail,
  queryMonthYardAmount,
  getInvoiceBuyer,
  queryInvoiceBuyerById,
  togetherSendEmail,
  likeSearchEmail,
  queryEmailsByCustomerId
} from '@/api/fms'
import PayQrcode from '@/views/components/PayQrcode'
import { getCustomer, getCustomerInfo, getSupplier, downloadExcel } from '@/api/common'
import { Empty } from 'ant-design-vue'
import debounce from 'lodash/debounce'
import cumsBill from '@/views/fms/bill/CumsBill'
import fmsPayBill from '@/views/fms/bill/FmsPayBill'
import fmsPayStateBill from '@/views/fms/bill/FmsPayStatementBill'
import itmsBill from '@/views/fms/bill/FmsItmsBill'
import MmsInvoiceNoticeBill from '@/views/fms/bill/MmsInvoiceNoticeBill'
import SaleOutStockBill from '@/views/fms/bill/SaleOutStockBill'
import InvoiceSheetBill from '@/views/fms/bill/InvoiceSheetBill'
import TempDropBoxSheetBill from '@/views/fms/bill/TempDropBoxSheetBill'
import YmsBalanceNoticeBill from '@/views/fms/bill/YmsBalanceNoticeBill'
import { getWmsBalanceFunc, getWmsPaymentFunc } from '@/api/wms/sale'
export default {
  components: {
    MmsInvoiceNoticeBill,
    STable,
    EditTable,
    PayQrcode,
    Empty,
    cumsBill,
    fmsPayBill,
    fmsPayStateBill,
    itmsBill,
    SaleOutStockBill,
    InvoiceSheetBill,
    TempDropBoxSheetBill,
    YmsBalanceNoticeBill
  },
  data() {
    this.lastFetchId = 0
    this.lastFetchId2 = 0;
    this.invoiceBuyerFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    this.getDepartmentCustomer2 = debounce(this.getDepartmentCustomer2, 800);
    this.getInvoiceBuyerInfo = debounce(this.getInvoiceBuyerInfo, 800)
    this.handleSearchEmails = debounce(this.handleSearchEmails, 500)
    return {
      printPaymentSheetVisible: false,
      printPaymentSheetLoading: false,
      printPaymentSheetBill: {
        id: `printPaymentSheetBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      },
      printInvoiceSheetVisible: false,
      printInvoiceSheetLoading: false,
      printInvoiceSheetBill: {
        id: `printInvoiceSheetBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      },
      printSaleOutStockBillModel: false,
      printSaleOutStockBillModelLoading: false,
      printSaleOutStockBill: {
        id: 'printSaleOutStockBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printInvoiceNoticeBillModel: false,
      printInvoiceNoticeBillModelLoading: false,
      printMmsInvoiceNoticeBill: {
        id: 'printMmsInvoiceNoticeBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printItmsBillModel: false,
      printItmsBillModelLoading: false,
      printItmsBill: {
        id: 'printItmsBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printCumsBillModel: false,
      printCumsBillModelLoading: false,
      printCumsBill: {
        id: 'printCumsBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printPayStatementsBillModel: false,
      printPayStatementsBillModelLoading: false,
      printStatementsPayBill: {
        id: 'printStatementsPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printPayBillModel: false,
      printPayBillModelLoading: false,
      printPayBill: {
        id: 'printPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printTempDropBoxSheetVisible: false,
      printTempDropBoxSheetLoading: false,
      printTempDropBoxSheetBill: {
        id: `printTempDropBoxSheetBill`,
        popTitle: ``,
        extraCss: ``,
        extraHead: `<meta http-equiv="Content-Language" content="zh-cn" />`
      },
      queryParam1: {
        department: 1,
        status: 3,
        order_type: 1
      },
      queryParam2: {
        department: 2,
        status: 3
      },
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      invoiceBuyerInfo: undefined,
      invoiceBuyerOps: [],
      buyerfetching: false,
      confirmLoading: false,
      mainTabKey: 'charge',
      chargeColumns: [],
      otherDepartmentChargeColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '流水号',
          dataIndex: 'record_no'
        },
        {
          title: '资金是否到账',
          dataIndex: 'received',
          customRender: (text, record) => {
            return record.cash_record_list ? record.cash_record_list.length > 0 ? '通联已收款' : text : text
          }
        },
        {
          title: '业务编号',
          dataIndex: 'order_num_list',
          scopedSlots: { customRender: 'orderNumList' },
          width: 200
        },
        {
          title: '业务类型',
          dataIndex: 'order_type_name'
        },
        // {
        //   title: '客户名称',
        //   dataIndex: 'customer'
        // },
        {
          title: '审核状态',
          dataIndex: 'status',
          customRender: text => {
            return text >= 0 ? this.GLOBAL.feeStatusMaps[text].name : ''
          }
        },
        {
          title: '金额',
          dataIndex: 'sum_amount',
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '币种',
          dataIndex: 'currency'
        },
        {
          title: '税率',
          dataIndex: 'tax_rate_info',
          customRender: text => {
            return text && text.tax_desc
          }
        },
        {
          title: '客户名称（发票抬头）',
          dataIndex: 'invoice_info',
          scopedSlots: { customRender: 'invoiceInfo' }
        },
        {
          title: '发票信息',
          dataIndex: 'invoice_detail',
          scopedSlots: { customRender: 'invoiceDetail' }
        },
        {
          title: '现金交易信息',
          dataIndex: 'cash_record_list',
          scopedSlots: { customRender: 'cashRecordList' }
        },
        {
          title: '申请备注',
          dataIndex: 'apply_remark'
        },
        {
          title: '其他信息',
          dataIndex: 'otherInfo',
          scopedSlots: { customRender: 'otherInfo' }
        },
        {
          title: '申请人',
          dataIndex: 'applicant_name'
        },
        {
          title: '申请时间',
          dataIndex: 'apply_time'
        },
        {
          title: '驳回原因',
          dataIndex: 'deny_reason'
        },
        {
          title: '驳回人',
          dataIndex: 'deny_person'
        },
        {
          title: '驳回日期',
          dataIndex: 'deny_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      ymsDepartmentChargeColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '流水号',
          dataIndex: 'record_no'
        },
        {
          title: '资金是否到账',
          dataIndex: 'received',
          customRender: (text, record) => {
            return record.cash_record_list ? record.cash_record_list.length > 0 ? '通联已收款' : text : text
          }
        },
        {
          title: '业务编号',
          dataIndex: 'order_num_list',
          scopedSlots: { customRender: 'orderNumList' },
          width: 200
        },
        {
          title: '费用种类',
          dataIndex: 'order_fee_class_str'
        },
        {
          title: '堆场类型',
          dataIndex: 'yard_type',
          customRender: (text) => {
            if (text === 1) {
              return '浦东堆场'
            } else if (text === 2) {
              return '江东堆场'
            } else if (text === 4) {
              return '钢四堆场'
            } else if (text === 5) {
              return '华东堆场'
            } else {
              return '-'
            }
          }
        },
        {
          title: '开票方式',
          dataIndex: 'scan_invoice',
          customRender: (text) => {
            if (text === 0) {
              return '申请开票'
            } else {
              return '扫码开票'
            }
          }
        },
        // {
        //   title: '客户名称',
        //   dataIndex: 'customer'
        // },
        {
          title: '审核状态',
          dataIndex: 'status',
          customRender: text => {
            return text >= 0 ? this.GLOBAL.feeStatusMaps[text].name : ''
          }
        },
        {
          title: '金额',
          dataIndex: 'sum_amount',
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '币种',
          dataIndex: 'currency'
        },
        {
          title: '税率',
          dataIndex: 'tax_rate_info',
          customRender: text => {
            return text && text.tax_desc
          }
        },
        {
          title: '客户名称（发票抬头）',
          dataIndex: 'invoice_info',
          scopedSlots: { customRender: 'invoiceInfo' }
        },
        {
          title: '发票信息',
          dataIndex: 'invoice_detail',
          scopedSlots: { customRender: 'invoiceDetail' }
        },
        {
          title: '现金交易信息',
          dataIndex: 'cash_record_list',
          scopedSlots: { customRender: 'cashRecordList' }
        },
        {
          title: '申请备注',
          dataIndex: 'apply_remark'
        },
        {
          title: '其他信息',
          dataIndex: 'otherInfo',
          scopedSlots: { customRender: 'otherInfo' }
        },
        {
          title: '申请人',
          dataIndex: 'applicant_name'
        },
        {
          title: '申请时间',
          dataIndex: 'apply_time'
        },
        {
          title: '驳回原因',
          dataIndex: 'deny_reason'
        },
        {
          title: '驳回人',
          dataIndex: 'deny_person'
        },
        {
          title: '驳回日期',
          dataIndex: 'deny_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      fqmsDepartmentChargeColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '流水号',
          dataIndex: 'record_no'
        },
        {
          title: '资金是否到账',
          dataIndex: 'received',
          customRender: (text, record) => {
            return record.cash_record_list ? record.cash_record_list.length > 0 ? '通联已收款' : text : text
          }
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          customRender: text => {
            return text >= 0 ? this.GLOBAL.feeStatusMaps[text].name : ''
          }
        },
        {
          title: '金额',
          dataIndex: 'sum_amount',
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '币种',
          dataIndex: 'currency'
        },
        {
          title: '客户名称（发票抬头）',
          dataIndex: 'customer'
        },
        {
          title: '申请备注',
          dataIndex: 'apply_remark'
        },
        {
          title: '申请人',
          dataIndex: 'applicant_name'
        },
        {
          title: '申请时间',
          dataIndex: 'apply_time'
        },
        {
          title: '发票信息',
          dataIndex: 'invoice_detail',
          scopedSlots: { customRender: 'invoiceDetail' }
        },
        {
          title: '驳回原因',
          dataIndex: 'deny_reason'
        },
        {
          title: '驳回人',
          dataIndex: 'deny_person'
        },
        {
          title: '驳回日期',
          dataIndex: 'deny_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      payColumns: [],
      otherDepartmentPayColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '流水号',
          dataIndex: 'record_no'
        },
        {
          title: '业务编号',
          dataIndex: 'order_num_list',
          scopedSlots: { customRender: 'orderNumList' },
          width: 200
        },
        {
          title: '客户名称',
          dataIndex: 'customer'
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          customRender: text => {
            return text >= 0 ? this.GLOBAL.feeStatusMaps[text].name : ''
          }
        },
        {
          title: '金额',
          dataIndex: 'sum_amount',
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '币种',
          dataIndex: 'currency'
        },
        {
          title: '付款类型',
          dataIndex: 'is_advance',
          customRender: text => {
            return text === 1 ? '后付' : '预付'
          }
        },
        {
          title: '付款方式',
          dataIndex: 'pay_method',
          customRender: text => {
            for (const item of this.paymentList) {
              if (item.value === text) {
                return item.name
              }
            }
            return ''
          }
        },
        {
          title: '发票信息',
          dataIndex: 'invoice_detail',
          scopedSlots: { customRender: 'invoiceDetail' }
        },
        {
          title: '申请备注',
          dataIndex: 'apply_remark'
        },
        {
          title: '申请人',
          dataIndex: 'applicant_name'
        },
        {
          title: '申请时间',
          dataIndex: 'apply_time'
        },
        {
          title: '驳回原因',
          dataIndex: 'deny_reason'
        },
        {
          title: '驳回人',
          dataIndex: 'deny_person'
        },
        {
          title: '驳回日期',
          dataIndex: 'deny_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      ymsDepartmentPayColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '流水号',
          dataIndex: 'record_no'
        },
        {
          title: '业务编号',
          dataIndex: 'order_num_list',
          scopedSlots: { customRender: 'orderNumList' },
          width: 200
        },
        {
          title: '费用种类',
          dataIndex: 'order_fee_class_str'
        },
        {
          title: '堆场类型',
          dataIndex: 'yard_type',
          customRender: (text) => {
            if (text === 1) {
              return '浦东堆场'
            } else if (text === 2) {
              return '江东堆场'
            } else if (text === 4) {
              return '钢四堆场'
            } else if (text === 5) {
              return '华东堆场'
            } else {
              return '-'
            }
          }
        },
        {
          title: '客户名称',
          dataIndex: 'customer'
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          customRender: text => {
            return text >= 0 ? this.GLOBAL.feeStatusMaps[text].name : ''
          }
        },
        {
          title: '金额',
          dataIndex: 'sum_amount',
          customRender: text => {
            return (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
          }
        },
        {
          title: '币种',
          dataIndex: 'currency'
        },
        {
          title: '付款类型',
          dataIndex: 'is_advance',
          customRender: text => {
            return text === 1 ? '后付' : '预付'
          }
        },
        {
          title: '付款方式',
          dataIndex: 'pay_method',
          customRender: text => {
            for (const item of this.paymentList) {
              if (item.value === text) {
                return item.name
              }
            }
            return ''
          }
        },
        {
          title: '发票信息',
          dataIndex: 'invoice_detail',
          scopedSlots: { customRender: 'invoiceDetail' }
        },
        {
          title: '申请备注',
          dataIndex: 'apply_remark'
        },
        {
          title: '申请人',
          dataIndex: 'applicant_name'
        },
        {
          title: '申请时间',
          dataIndex: 'apply_time'
        },
        {
          title: '成本月份',
          scopedSlots: { customRender: 'costDateSlot' }
        },
        {
          title: '驳回原因',
          dataIndex: 'deny_reason'
        },
        {
          title: '驳回人',
          dataIndex: 'deny_person'
        },
        {
          title: '驳回日期',
          dataIndex: 'deny_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData1: parameter => {
        // charge_list
        return getBizAuditPage('charge_list', Object.assign(parameter, this.queryParam1)).then(res => {
          if (this.queryParam1.department === 6) {
            this.chargeColumns = this.ymsDepartmentChargeColumns
          } else if (this.queryParam1.department === 5) {
            this.chargeColumns = this.fqmsDepartmentChargeColumns
          } else {
            this.chargeColumns = this.otherDepartmentChargeColumns
          }
          return res
        })
      },
      loadData2: parameter => {
        return getBizAuditPage('pay_list', Object.assign(parameter, this.queryParam2)).then(res => {
          if (this.queryParam2.department === 6) {
            this.payColumns = this.ymsDepartmentPayColumns
          } else {
            this.payColumns = this.otherDepartmentPayColumns
          }
          return res
        })
      },
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        }
      ],
      wmsOrderTypeOps: [
        {
          value: 1, name: '查验'
        },
        {
          value: 2, name: '暂落箱'
        },
        {
          value: 3, name: '仓储租赁'
        }
      ],
      wmsChargeTypeOps: [
        {
          value: 1, name: '现金'
        },
        {
          value: 2, name: '月结'
        }
      ],
      currentData: {},
      detailData: [],
      balanceData: [],
      balanceCurrency: '',
      balanceExchangeRate: null,
      balanceStatic: {
        charge: 0,
        pay: 0,
        profit: 0
      },
      selectedRowKeys: [],
      selectedRows: [],
      rejectFormVisible: false, // 驳回页面
      rejectForm: {
        // 驳回表单
        id: '',
        deny_reason: '',
        fee_type: 1
      },
      rejectRules: {
        deny_reason: [{ required: true, message: '请输入驳回原因', trigger: 'blur' }]
      },
      userInfo: this.$store.getters.userInfo,
      supplierOps: [], // 供应商
      printPayBillResult: [],
      printPayBillRecord: {},
      invoiceList: [],
      // ----------------------- 编辑发票信息 start
      editInvoiceRecordVisible: false,
      editInvoiceRecordLoading: false,
      editInvoiceRecordColumns: [
        {
          title: `发票号`,
          dataIndex: `invoice_no`
        },
        {
          title: `税价总计`,
          dataIndex: `tax_price_total`,
          extra: {
            type: `number`,
            extraFunc: this.calcTotal
          }
        },
        {
          title: `价格`,
          dataIndex: `price_total`,
          extra: {
            type: `number`
          }
        },
        {
          title: `税额`,
          dataIndex: `tax_total`,
          extra: {
            type: `number`
          }
        }
      ],
      editInvoiceRecordData: [],
      printInvoiceVisible: false,
      printInvoiceLoading: false,
      printInvoiceColumns: [
        {
          title: `发票号`,
          dataIndex: `invoice_no`
        },
        {
          title: `税价总计`,
          dataIndex: `invoice_total_price_tax`
        },
        {
          title: `价格`,
          dataIndex: `invoice_total_price`
        },
        {
          title: `税额`,
          dataIndex: `invoice_total_tax`
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' }
        }
      ],
      printInvoiceData: [],
      printInvoiceAuditId: null,
      auditRecordId: undefined,
      // ----------------------- 打印开票单数据源
      invoiceData: [],
      showTotal: '选择条数:0 合计:0',
      yardScanInvoiceWarning: '',
      taxRate: 0,
      storeInvoiceVisible: false,
      scanQRCodeStatus: 0,
      storeInvoiceInfo: undefined,
      storeInvoiceInfoList: [],
      anewPayInfo: undefined,
      anewChargeVisible: false,
      anewChargeLoading: false,
      payInfo: undefined,
      storeScene: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '计划号',
          dataIndex: 'serial_num'
        },
        {
          title: '报关单号',
          dataIndex: 'report_num'
        },
        {
          title: '委托客户',
          dataIndex: 'customer_name'
        },
        {
          title: '费用信息',
          dataIndex: 'wms_warehouse_fee_info_list',
          scopedSlots: { customRender: 'fee_info' }
        },
        {
          title: '合计',
          dataIndex: 'amount'
        },
        {
          title: '现金收款',
          dataIndex: 'cash_record_list',
          scopedSlots: { customRender: 'cashRecordList' },
          width: 300
        },
        {
          title: '未收款金额',
          dataIndex: 'un_charge_amount'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      storeDropScene: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '业务编号',
          dataIndex: 'serial_num'
        },
        {
          title: '委托客户',
          dataIndex: 'customer_name'
        },
        {
          title: '费用信息',
          dataIndex: 'wms_warehouse_fee_info_list',
          scopedSlots: { customRender: 'fee_info' }
        },
        {
          title: '合计',
          dataIndex: 'amount'
        },
        {
          title: '现金收款',
          dataIndex: 'cash_record_list',
          scopedSlots: { customRender: 'cashRecordList' },
          width: 300
        },
        {
          title: '未收款金额',
          dataIndex: 'un_charge_amount'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      storeSceneList: [],
      storeSceneTotal: 0,
      taxRateOps: [],
      sceneRules: {
        customer_name: [{ required: true, message: '请输入委托单位/客户名称', trigger: 'blur' }],
        tax_rate: [{ required: true, message: '请选择税率', trigger: 'change' }]
      },
      customerInfo2: undefined,
      fetching2: false,
      sceneCustomer: [],
      sceneForm: {
        customer_id: '',
        customer_name: '',
        tax_rate: undefined
      },
      ysmStoreInvoiceVisible: false,
      ymsScanQRCodeStatus: 0,
      ymsSceneForm: {
        customer_id: '',
        customer_name: '',
        tax_rate: undefined
      },
      ymsSceneRules: {
        customer_name: [{ required: true, message: '请输入委托单位/客户名称', trigger: 'blur' }],
        tax_rate: [{ required: true, message: '请选择税率', trigger: 'change' }]
      },
      ymsStoreInvoiceInfo: undefined,
      ymsStoreInvoiceInfoList: [],
      ymsStoreLoading: false,
      ymsStoreScene: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '箱经营人',
          dataIndex: 'container_operator'
        },
        {
          title: '箱号',
          dataIndex: 'container_no'
        },
        {
          title: '箱型',
          dataIndex: 'container_type'
        },
        {
          title: '车牌号',
          dataIndex: 'plate_number'
        },
        {
          title: '创建时间',
          dataIndex: 'create_date'
        },
        {
          title: '费用信息',
          dataIndex: 'yms_fee_info_list',
          scopedSlots: { customRender: 'fee_info' }
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' }
        }
      ],
      ymsStoreSceneList: [],
      ymsStoreMsg: '票数：0 合计：0',
      storeSceneMessage: '票数：0 合计：0',
      popVisible: false,
      popVisible2: false,
      popVisible3: false,
      popVisible4: false,
      popVisible5: false,
      sceneChargeType: 1,
      posChargeTrxId: undefined,
      invoiceTypeOps: {
        0: { value: 0, name: '未知' },
        1: { value: 1, name: '专用发票' },
        2: { value: 2, name: '普通发票' },
        3: { value: 3, name: '免税发票' },
        4: { value: 4, name: 'TMS奥货' }
      },
      orderTypeOps: [
        { name: '国贸', value: 1 },
        { name: '奥货', value: 2 },
        { name: '订舱', value: 3 },
        { name: '行政业务', value: 4 },
        { name: '拖运业务', value: 5 }
      ],
      groupIdOps: [
        { name: '浦东堆场', value: 1 },
        { name: '江东堆场', value: 2 },
        { name: '钢四堆场', value: 4 },
        { name: '华东堆场', value: 5 }
      ],
      scanInvoiceOps: [
        { name: '扫码开票', value: 1 },
        { name: '申请开票', value: 0 }
      ],
      // 资金是否到账
      receivedOps: [
        { value: 1, name: '已到账' },
        { value: 2, name: '部分到账' },
        { value: 0, name: '未到账' }
      ],
      printYmsBalanceNoticeSheetVisible: false,
      printYmsBalanceNoticeList: [],
      printYmsBalanceNoticeData: {},
      printYmsBalanceNoticeSheetBill: {
        id: 'printYmsBalanceNoticeSheetBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      listPayOrderFeeInfoColumns: [
        {
          title: '提单号',
          dataIndex: 'bill_no'
        },
        {
          title: '箱号',
          dataIndex: 'container_no'
        },
        {
          title: '金额',
          dataIndex: 'amount'
        }
      ],
      listPayOrderFeeInfoData: [],
      listPayOrderFeeInfoVisible: false,
      ymsOrderPassVisible: false,
      ymsOrderPassLoading: false,
      ymsOrderPassForm: {
        cost_begin_date: null,
        cost_end_date: null
      },
      ymsOrderPassRules: {
        cost_begin_date: [{ required: true, message: '请选择成本月份', trigger: 'blur' }],
        cost_end_date: [{ required: true, message: '请选择成本月份', trigger: 'blur' }]
      },
      updateCostDateVisible: false,
      updateCostDateLoading: false,
      costMode: ['month', 'month'],
      costDate: [],
      applyInvoiceVisible: false,
      applyInvoiceLoading: false,
      applyInvoiceForm: {
        invoice_type: '0',
        many_together: 'N',
        taxation_method: '0',
        price_tax_mark: '1',
        invoice_list_mark: '0',
        audit_id: null,
        invoice_type_code: null,
        tax_tray_id: null,
        buyer_id: null,
        buyer_name: null,
        buyer_tax_no: null,
        buyer_address_phone: null,
        buyer_telphone: null,
        buyer_bank_account: null,
        buyer_account: null,
        sale_company_name: null,
        sale_company_tax_no: null,
        sale_company_address_mobile: null,
        sale_company_bank_account: null,
        payee: null,
        checker: null,
        drawer: null,
        remarks: null,
        invoice_subject_data: []
      },
      applyInvoiceRules: {
        invoice_type_code: [{ required: true, message: '请选择开票类型', trigger: 'change' }],
        many_together: [{ required: true, message: '请选择单票还是多票', trigger: 'change' }],
        tax_tray_id: [{ required: true, message: '请选择税盘', trigger: 'change' }],
        buyer_name: [{ required: true, message: '请填写购买单位名称', trigger: 'blur' }],
        sale_company_name: [{ required: true, message: '请填写销货单位名称', trigger: 'blur' }],
        sale_company_tax_no: [{ required: true, message: '请填写销货单位税号', trigger: 'blur' }],
        sale_company_address_mobile: [{ required: true, message: '请填写购买单位地址电话', trigger: 'blur' }],
        sale_company_bank_account: [{ required: true, message: '请填写购买单位银行账户', trigger: 'blur' }],
        payee: [{ required: true, message: '请填写收款人', trigger: 'blur' }],
        checker: [{ required: true, message: '请填写复核人', trigger: 'blur' }],
        drawer: [{ required: true, message: '请填写开票人', trigger: 'blur' }]
      },
      // taxtrayList: [{ value: '1', name: '税盘1' }, { value: '2', name: '税盘2' }, { value: '3', name: '税盘3' }],
      taxtrayList: [],
      taxRateList: [{ value: 0, name: 0 },
        { value: 0.01, name: 0.01 },
        { value: 0.03, name: 0.03 },
        { value: 0.05, name: 0.05 },
        { value: 0.06, name: 0.06 },
        { value: 0.09, name: 0.09 },
        { value: 0.13, name: 0.13 },
        { value: 0.15, name: 0.15 },
        { value: 0.16, name: 0.16 },
        { value: 0.17, name: 0.17 }],
      totalInvoiceAmount: 0.00,
      invoiceSubjectColumns: [
        {
          title: `货物或应税劳务,服务名称`,
          dataIndex: `goods_name`,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  let subject = null
                  debugger
                  for (const item of this.secondInvoiceSubjectList) {
                    if (item.id === val) {
                      subject = item
                    }
                  }
                  this.invoiceSubjectData[index]['subject_id'] = subject.id
                  this.invoiceSubjectData[index]['goods_name'] = subject.subject_name
                  this.invoiceSubjectData[index]['goods_code'] = subject.subject_code
                  this.invoiceSubjectData[index]['goods_specification'] = subject.subject_spec
                  this.invoiceSubjectData[index]['goods_unit'] = subject.subject_unit
                  this.invoiceSubjectData[index]['goods_tax_rate'] = subject.tax_rate
                  this.calcTotalPrice(true)
                }}
              >
                {this.secondInvoiceSubjectList.map(op => {
                  var opName = op.subject_name
                  if (op.subject_spec) {
                    opName = opName + '/' + op.subject_spec
                  }
                  opName = opName + '/' + op.tax_rate
                  return <a-select-option key={op.id}>{opName}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `科目编号`,
          dataIndex: `goods_code`,
          width: 160
        },
        {
          title: `规格型号`,
          dataIndex: `goods_specification`,
          width: 100
        },
        {
          title: `单位`,
          dataIndex: `goods_unit`,
          width: 100
        },
        {
          title: `数量`,
          dataIndex: `goods_quantity`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_quantity'] = val
                  if (this.invoiceSubjectData[index]['goods_price']) {
                    this.invoiceSubjectData[index]['goods_total_price'] = this.invoiceSubjectData[index]['goods_price'] * val
                    var totalPrice = this.invoiceSubjectData[index]['goods_total_price']
                    if (this.invoiceSubjectData[index]['goods_tax_rate']) {
                      var taxRate = this.invoiceSubjectData[index]['goods_tax_rate']
                      if (this.applyInvoiceForm.price_tax_mark === '0') {
                        this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice * taxRate).toFixed(2)
                      } else {
                        this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice / (1 + taxRate) * taxRate).toFixed(2)
                      }
                    } else {
                      this.invoiceSubjectData[index]['goods_total_tax'] = 0
                    }
                  }
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `单价`,
          dataIndex: `goods_price`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_price'] = val
                  if (this.invoiceSubjectData[index]['goods_quantity']) {
                    this.invoiceSubjectData[index]['goods_total_price'] = this.invoiceSubjectData[index]['goods_quantity'] * val
                    var totalPrice = this.invoiceSubjectData[index]['goods_total_price']
                    if (this.invoiceSubjectData[index]['goods_tax_rate'] >= 0) {
                      var taxRate = this.invoiceSubjectData[index]['goods_tax_rate']
                      if (this.applyInvoiceForm.price_tax_mark === '0') {
                        this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice * taxRate).toFixed(2)
                      } else {
                        this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice / (1 + taxRate) * taxRate).toFixed(2)
                      }
                    }
                  }
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `金额`,
          dataIndex: `goods_total_price`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_price'] = val
                  if (this.invoiceSubjectData[index]['goods_tax_rate']) {
                    var taxRate = this.invoiceSubjectData[index]['goods_tax_rate']
                    if (this.applyInvoiceForm.price_tax_mark === '0') {
                      this.invoiceSubjectData[index]['goods_total_tax'] = (val * taxRate).toFixed(2)
                    } else {
                      this.invoiceSubjectData[index]['goods_total_tax'] = (val / (1 + taxRate) * taxRate).toFixed(2)
                    }
                  } else {
                    this.invoiceSubjectData[index]['goods_total_tax'] = 0
                  }
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `税率`,
          dataIndex: `goods_tax_rate`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_tax_rate'] = val
                  if (this.invoiceSubjectData[index]['goods_total_price']) {
                    var totalPrice = this.invoiceSubjectData[index]['goods_total_price']
                    if (this.applyInvoiceForm.price_tax_mark === '0') {
                      this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice * val).toFixed(2)
                    } else {
                      this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice / (1 + val) * val).toFixed(2)
                    }
                  }
                  this.calcTotalPrice(false)
                }}
              >
                {this.taxRateList.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `税额`,
          dataIndex: `goods_total_tax`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_tax'] = val
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `开票备注(多票)`,
          dataIndex: `special_remark`,
          width: 200,
          extra: { type: 'text' }
        }
      ],
      invoiceSubjectData: [],
      applyInvoiceRecord: {},
      secondInvoiceSubjectList: [],
      emailForm: {
        invoice_nos: [],
        emails: []
      },
      invoiceEmails: [],
      sendEmailDisabled: false
    }
  },
  created() {
    getSupplier({ department: null }).then(res => {
      this.supplierOps = res
    })
    getchargeTax().then(v => {
      this.taxRateOps = v
    })
    listSecondInvoiceSubject({}).then(v => {
      this.secondInvoiceSubjectList = v
    })
  },
  watch: {
    selectedRowKeys: function() {
      let total = 0
      if (this.selectedRows && this.selectedRows.length > 0) {
        for (const item of this.selectedRows) {
          total += item.sum_amount
        }
      }
      this.showTotal = `选择条数:${this.selectedRowKeys.length} 合计:${total}`
    },
    storeSceneList: function() {
      this.storeSceneTotal = 0
      let total = 0
      for (const item of this.storeSceneList) {
        total += item.amount
        this.storeSceneTotal += item.un_charge_amount
      }
      this.storeSceneMessage = `票数：${this.storeSceneList.length} 合计：${total} 需收款合计：${this.storeSceneTotal}`
    },
    ymsStoreSceneList: function() {
      let total = 0
      for (const item of this.ymsStoreSceneList) {
        for (const fee of item.yms_fee_info_list) {
          total += fee.amount
        }
      }
      this.ymsStoreMsg = `票数：${this.ymsStoreSceneList.length} 合计：${total}`
    }
  },
  methods: {
    calcTotalPrice(againFlag) {
      this.totalInvoiceAmount = 0
      this.invoiceSubjectData.forEach(v => {
        if (againFlag) {
          if (v.goods_total_price && v.goods_tax_rate) {
            // v.goods_total_price = v.goods_quantity * v.goods_price
            if (this.applyInvoiceForm.price_tax_mark === '0') {
              v.goods_total_tax = (v.goods_total_price * v.goods_tax_rate).toFixed(2)
            } else {
              v.goods_total_tax = (v.goods_total_price / (1 + v.goods_tax_rate) * v.goods_tax_rate).toFixed(2)
            }
          }
        }
        if (v.goods_total_price) {
          this.totalInvoiceAmount = this.totalInvoiceAmount + v.goods_total_price
          if (this.applyInvoiceForm.price_tax_mark === '0') {
            this.totalInvoiceAmount = this.totalInvoiceAmount + parseFloat(v.goods_total_tax)
          }
        }
      })
    },
    // 堆场应收应付总表导出
    exportYmsMonthChargePaySheet() {
      const reqParam = {
        'export_month': this.queryParam1['export_month']
      }
      exportYmsMonthChargePaySheet(reqParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      }).finally(() => {
        this.popVisible5 = false
      })
    },
    // 堆场应付账单查看退移箱费明细
    listYmsPayOrderFeeInfoRelation(record) {
      const reqParam = {
        'business_no': record.order_num_list[0],
        'yard_id': record.yard_type
      }
      listYmsPayOrderFeeInfoRelation(reqParam).then(res => {
        this.listPayOrderFeeInfoData = res
        if (res.length > 0) {
          this.listPayOrderFeeInfoVisible = true
        } else {
          this.$notification['info']({
            message: '提示',
            description: '此订单没有关联退移箱费支付信息'
          })
        }
      })
    },
    listPayOrderFeeInfoCancel() {
      this.listPayOrderFeeInfoVisible = false
      this.listPayOrderFeeInfoData = []
    },
    // 堆场开票明细导出
    exportYmsInvoiceDetailSheet() {
      const reqParam = {
        'invoice_start_date': this.queryParam1['invoice_start_date'],
        'invoice_end_date': this.queryParam1['invoice_end_date'],
        'scan_invoice': this.queryParam1['scan_invoice'],
        'yard_id': this.queryParam1['group_id']
      }
      exportYmsInvoiceDetailSheet(reqParam).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      }).finally(() => {
        this.popVisible4 = false
      })
    },
    printYmsBalanceNoticeSheet(record) {
      getBalanceNoticeSheet({ 'id': record.id }).then(res => {
        this.printYmsBalanceNoticeSheetVisible = true
        this.printYmsBalanceNoticeData = Object.assign({}, record)
        this.printYmsBalanceNoticeList = res
        record.is_print = 1
      })
    },
    removeFmsBusinessRecord(record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除?',
        onOk: () => {
          removeFmsBusinessRecord({ 'id': record.id }).then(res => {
            if (res) {
              this.$notification['success']({ message: '操作成功' });
            } else {
              this.$notification['error']({ message: '操作失败' });
            }
          }).finally(_ => {
            this.$refs.table1.refresh(true);
          })
        }
      })
    },
    createInvoiceRecord() {
      const param = {
        customer_id: this.ymsSceneForm.customer_id,
        tax_rate: this.ymsSceneForm.tax_rate,
        ticket_code_list: this.ymsStoreInvoiceInfoList
      }
      createInvoiceRecord(param).then(res => {
        if (res) {
          this.$notification['success']({ message: '操作成功' });
        } else {
          this.$notification['error']({ message: '操作失败' });
        }
      }).finally(_ => {
        this.$refs.table1.refresh(true);
        this.ysmStoreInvoiceClose();
      });
    },
    ymsDeleteInvoice(record) {
      for (let i = 0; i < this.ymsStoreInvoiceInfoList.length; i++) {
        if (this.ymsStoreInvoiceInfoList[i] === record.auth_code) {
          this.ymsStoreInvoiceInfoList.splice(i, 1);
          const idx = this.ymsStoreSceneList.findIndex(item => item.auth_code === record.auth_code)
          this.ymsStoreSceneList.splice(idx, 1)
        }
      }
    },
    getTicketInfo(ticketCode) {
      this.ymsStoreLoading = true
      getTicketInfo({ 'ticket_code': ticketCode }).then(res => {
        res.forEach(item => this.ymsStoreSceneList.push(item));
      }).catch(() => {
        const idx = this.ymsStoreInvoiceInfoList.findIndex(item => item === ticketCode)
        this.ymsStoreInvoiceInfoList.splice(idx, 1)
      }).finally(() => {
        this.ymsStoreLoading = false
      });
    },
    ymsGetStoreInvoiceInfo() {
      const ticketCode = this.$refs.ymsStoreInvoiceInput.$el.value
      if (ticketCode) {
        if (!this.ymsStoreInvoiceInfoList.includes(ticketCode)) {
          this.ymsStoreInvoiceInfoList.push(ticketCode);
          this.getTicketInfo(ticketCode);
        }
      }
      this.$refs.ymsStoreInvoiceInput.$el.value = '';
    },
    ymsPrev() {
      if (this.ymsScanQRCodeStatus - 1 === -1) {
        this.ysmStoreInvoiceClose();
      }
      this.ymsScanQRCodeStatus -= 1;
      if (this.ymsScanQRCodeStatus - 1 === 1) {
        this.$nextTick(_ => {
          this.$refs.ymsStoreInvoiceInput.focus();
        });
      } else if (this.ymsScanQRCodeStatus - 1 === 0) {
        this.ymsStoreInvoiceInfoList = [];
        this.ymsStoreSceneList = [];
      }
    },
    ymsNext() {
      if (this.ymsScanQRCodeStatus + 1 === 2) {
        this.ysmStoreInvoiceClose();
      }
      if (this.ymsScanQRCodeStatus + 1 === 1) {
        this.$refs.ymsSceneForm.validate(valid => {
          if (valid) {
            this.ymsScanQRCodeStatus += 1;
            this.$nextTick(_ => {
              this.$refs.ymsStoreInvoiceInput.focus();
            });
          } else {
            this.$message.warning('发票抬头和税率为必填项!');
          }
        });
      } else if (this.ymsScanQRCodeStatus + 1 === 2) {
        if (this.ymsStoreInvoiceInfoList.length === 0) {
          this.$message.warning('没有结费单!');
        }
      }
    },
    ysmStoreInvoiceClose() {
      this.ysmStoreInvoiceVisible = false;
      this.ymsScanQRCodeStatus = 0;
    },
    ymsStoreInvoice() {
      this.ysmStoreInvoiceVisible = true;
      this.ymsStoreInvoiceInfo = undefined;
      this.ymsStoreInvoiceInfoList = [];
      this.ymsStoreSceneList = [];
      this.ymsSceneForm = {
        customer_id: '',
        customer_name: '',
        tax_rate: 17 // 默认选中 %6 销项税
      }
      this.customerInfo2 = undefined;
    },
    // 导出贸易应付表
    tradeNoPaymentSheet() {
      getWmsPaymentFunc('exportTradeNoPaymentSheet').then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      });
    },
    // 导出贸易预收表
    tradeNoInvoicedSheet() {
      getWmsBalanceFunc('exportTradeNoInvoicedSheet').then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    // 导出贸易应收表
    tradeDebtSheet() {
      getWmsBalanceFunc('exportTradeDebtSheet').then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      });
    },
    storeInvoice() {
      this.storeInvoiceVisible = true
      this.storeInvoiceInfo = undefined
      this.storeInvoiceInfoList = []
      this.storeSceneList = []
      this.scanQRCodeStatus = 0
      this.sceneChargeType = 1
      this.posChargeTrxId = undefined
      let tax
      for (const item of this.taxRateOps) {
        if (item.name === '6%销项税') {
          tax = item.value
        }
      }
      this.sceneForm = {
        customer_name: '',
        customer_id: '',
        tax_rate: tax
      }
      this.customerInfo2 = undefined
      this.$nextTick(_ => {
        this.$refs.storeInvoiceInput.focus()
      })
    },
    storeInvoiceClose() {
      this.storeInvoiceVisible = false
    },
    next() {
      if (this.scanQRCodeStatus + 1 === 3) {
        this.storeInvoiceClose()
      }
      if (this.scanQRCodeStatus + 1 === 1) {
        if (this.storeSceneList.length === 0) {
          this.$message.warning('没有结费单!')
        } else {
          this.getWMSCustomerOps(this.storeSceneList[0].customer_name, this.storeSceneList[0].customer_id)
          this.scanQRCodeStatus += 1
        }
      } else if (this.scanQRCodeStatus + 1 === 2) {
        this.$refs.sceneForm.validate(valid => {
          if (valid) {
            this.scanQRCodeStatus += 1
            this.$nextTick(_ => {
              this.$refs.payInfoInput.focus()
              if (!(this.storeSceneTotal > 0)) {
                this.payInfo = '0'
                this.chargeFee()
              }
            })
          } else {
            this.$message.warning('发票抬头和税率为必填项!')
          }
        })
      }
    },
    prev() {
      if (this.scanQRCodeStatus - 1 === -1) {
        this.storeInvoiceClose()
      }
      if (this.scanQRCodeStatus - 1 === 0) {
        this.storeInvoiceInfoList = []
        this.storeSceneList = []
        this.$nextTick(_ => {
          this.$refs.storeInvoiceInput.focus()
        })
      }
      this.scanQRCodeStatus -= 1
    },
    getDepartmentCustomer2(value) {
      this.lastFetchId2 += 1;
      const fetchId2 = this.lastFetchId2;
      this.sceneCustomer = [];
      this.fetching2 = true;
      getCustomer({
        customerName: value
      }).then(res => {
        if (fetchId2 !== this.lastFetchId2) {
          return;
        }
        this.sceneCustomer = res;
        this.fetching2 = false;
      })
    },
    changeCustomerInfo2(value) {
      this.customerInfo2 = value;
      this.sceneForm.customer_name = value !== undefined ? value.label : null;
      this.sceneForm.customer_id = value !== undefined ? value.key : null;
      this.ymsSceneForm.customer_name = value !== undefined ? value.label : null;
      this.ymsSceneForm.customer_id = value !== undefined ? value.key : null;
      this.sceneCustomer = [];
      this.fetching2 = false;
    },
    getWMSCustomerOps(name, id) {
      getCustomerInfo({
        department: null,
        customerName: name,
        customerId: id
      }).then(res => {
        res.forEach(data => {
          this.customerInfo2 = {
            key: typeof data.value !== 'undefined' ? data.value : '',
            label: typeof data.name !== 'undefined' ? data.name : ''
          }
          this.sceneForm.customer_name = data.name !== undefined ? data.name : '';
          this.sceneForm.customer_id = data.value !== undefined ? data.value : '';
        })
      })
    },
    getStoreInvoiceInfo() {
      if (this.storeInvoiceInfo) {
        if (!this.storeInvoiceInfoList.includes(Number(this.storeInvoiceInfo))) {
          this.storeInvoiceInfoList.push(Number(this.storeInvoiceInfo))
          this.getWmsChargeInfo()
        }
      }
      this.storeInvoiceInfo = undefined
    },
    getWmsChargeInfo() {
      getWmsRecord({
        order_type: this.queryParam1.order_type,
        id_list: this.storeInvoiceInfoList
      }).then(res => {
        this.storeSceneList = res
      })
    },
    deleteInvoice(record) {
      for (let i = 0; i < this.storeInvoiceInfoList.length; i++) {
        if (this.storeInvoiceInfoList[i] === record.record_id) {
          this.storeInvoiceInfoList.splice(i, 1)
          this.getWmsChargeInfo()
        }
      }
    },
    chargeFee() {
      if (this.payInfo) {
        this.$refs.sceneForm.validate(valid => {
          if (valid) {
            scanQRCodeCharge({
              pay_info: this.payInfo,
              ticket_id_list: this.storeSceneList.map(e => e.record_id),
              customer_id: this.sceneForm.customer_id,
              tax_rate: this.sceneForm.tax_rate
            })
              .then(res => {
                if (res) {
                  this.$notification['success']({
                    message: '创建成功'
                  })
                  this.storeInvoiceVisible = false
                  this.$refs.table1.refresh(true)
                } else {
                  this.$notification['error']({
                    message: '创建失败'
                  })
                }
              })
              .finally(_ => {
                this.payInfo = undefined
              })
          }
        })
      }
    },
    changeChargeType(e) {
      if (e.target.value === 1) {
        this.$nextTick(_ => {
          this.$refs.payInfoInput.focus()
        })
      }
    },
    chargeByPos() {
      if (this.posChargeTrxId) {
        this.$refs.sceneForm.validate(valid => {
          if (valid) {
            posCharge({
              trx_id: this.posChargeTrxId,
              ticket_id_list: this.storeSceneList.map(e => e.record_id),
              customer_id: this.sceneForm.customer_id,
              tax_rate: this.sceneForm.tax_rate
            })
              .then(res => {
                if (res) {
                  this.$notification['success']({
                    message: '创建成功'
                  })
                  this.storeInvoiceVisible = false
                  this.$refs.table1.refresh(true)
                } else {
                  this.$notification['error']({
                    message: '创建失败'
                  })
                }
              })
              .finally(_ => {
                this.posChargeTrxId = undefined
              })
          }
        })
      } else {
        this.$notification['error']({
          message: '交易号或凭证号未填写'
        })
      }
    },
    anewChargeModal(record) {
      this.anewPayInfo = undefined
      this.auditRecordId = record.id
      this.anewChargeVisible = true
      this.$nextTick(_ => {
        this.$refs.anewPayInfoInput.focus()
      })
    },
    anewChargeFee() {
      if (this.anewPayInfo) {
        this.anewChargeLoading = true
        anewScanQRCodeCharge({
          authCode: this.anewPayInfo,
          auditId: this.auditRecordId
        })
        .then(res => {
          if (res) {
            this.$notification['success']({
              message: '扫码成功'
            })
            this.anewChargeVisible = false
            this.$refs.table1.refresh(true)
            } else {
              this.$notification['error']({
                message: '扫码失败'
              })
            }
        })
        .finally(_ => {
          this.anewPayInfo = undefined
          this.anewChargeLoading = false
        })
      }
    },
    // 暂落箱开票通知单
    tempDropBoxSheet(record) {
      this.currentData = record
      getTempDropBoxSheetBill({
        auditRecordId: record.id
      })
        .then(res => {
          this.invoiceData = res
        })
        .finally(_ => {
          this.printTempDropBoxSheetVisible = true
        })
    },
    calcTotal(value, newData, target) {
      if (target.tax_price_total) {
        // 税额计算公式  税价总计 * 税率 / (1 + 税率)
        const taxAmount = (target.tax_price_total * this.taxRate) / (1 + this.taxRate)
        target[`price_total`] = (target.tax_price_total - taxAmount).toFixed(2)
        target[`tax_total`] = taxAmount.toFixed(2)
      } else {
        target[`price_total`] = 0
        target[`tax_total`] = 0
      }
      return newData
    },
    moment,
    // 编辑发票信息模态框关闭事件
    cancelEditInvoiceRecord() {
      this.editInvoiceRecordVisible = false
      this.editInvoiceRecordLoading = false
      this.editInvoiceRecordData = []
      this.$refs.table1.refresh(false)
    },
    // 编辑发票信息
    editInvoiceRecord(record) {
      this.auditRecordId = record.id
      this.editInvoiceRecordVisible = true
      getInvoiceRecordByAuditRecordId({
        auditRecordId: this.auditRecordId
      }).then(res => {
        if (record.department !== 5 && res.length === 0) {
          getTaxRateById({ id: record.tax_rate }).then(item => {
            this.taxRate = item.rate
            // 税额计算公式  税价总计 * 税率 / (1 + 税率)
            const taxAmount = (record.sum_amount * this.taxRate) / (1 + this.taxRate)
            this.editInvoiceRecordData.push({
              invoice_no: '', // 发票号
              tax_price_total: record.sum_amount, // 税价总计
              price_total: (record.sum_amount - taxAmount).toFixed(2), // 价格
              tax_total: taxAmount.toFixed(2) // 税额
            })
          })
        } else {
          this.editInvoiceRecordData = res
        }
      })
    },
    // 提交发票信息修改记录
    handleEditInvoiceRecordSubmit() {
      this.editInvoiceRecordLoading = true
      const invoiceInfoData = []
      this.editInvoiceRecordData.forEach(item => {
        invoiceInfoData.push({
          id: item.id,
          invoice_no: item.invoice_no.trim(),
          price_total: item.price_total,
          tax_total: item.tax_total,
          tax_price_total: item.tax_price_total
        })
      })
      const data = {
        id: this.auditRecordId,
        invoice_info_details: invoiceInfoData
      }
      fmsSaveInvoice(data)
        .then(res => {
          if (res) {
            this.$message.success(`操作成功~`)
            this.$refs.table1.clearSelected()
            this.selectedRows = []
            this.$refs.table1.refresh(false)
            this.cancelEditInvoiceRecord()
          } else {
            this.$message.error(`操作失败~`)
          }
        }).catch(_ => {
          this.editInvoiceRecordLoading = false
        })
        .finally(_ => {
          // this.cancelEditInvoiceRecord()
        })
    },
    getEditInvoiceRecordData(data) {
      this.editInvoiceRecordData = data
    },
    getTableDateError() {
      this.$notification['error']({
        message: '提示',
        description: '该发票信息不可删除！'
      })
    },
    filterOption,
    getRandomCode,
    getChargeQrCode,
    numberToChinese,
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    selectItem(record, selected, selectedRows) {
      if (selected) {
        let flag = true
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].id === record.id) {
            flag = false
          }
        }
        if (flag) {
          this.selectedRows.push(record)
        }
      } else {
        for (let index = 0; index < this.selectedRows.length; index++) {
          if (this.selectedRows[index].id === record.id) {
            this.selectedRows.splice(index, 1)
          }
        }
      }
    },
    selectAllItem(selected, selectedRows, changeRows) {
      if (selected) {
        changeRows.forEach(data => {
          let flag = true
          this.selectedRows.forEach(item => {
            if (data.id === item.id) {
              flag = false
            }
          })
          if (flag) {
            this.selectedRows.push(data)
          }
        })
      } else {
        for (const item of changeRows) {
          for (let index = 0; index < this.selectedRows.length; index++) {
            if (item.id === this.selectedRows[index].id) {
              this.selectedRows.splice(index, 1)
            }
          }
        }
      }
    },
    changeOrderType() {
      if (this.mainTabKey === 'charge') {
        this.$refs.table1.clearSelected()
        this.$refs.table1.refresh(true)
      }
      this.selectedRows = []
    },
    changeDepartment() {
      if (this.mainTabKey === 'charge') {
        this.$refs.table1.clearSelected()
        this.$refs.table1.refresh(true)
        if (this.queryParam1.department === 6) {
          queryMonthYardAmount().then(res => {
            this.yardScanInvoiceWarning = '本月道口收费总额：' + res.chargeAmount + '；本月扫码开票金额：' + res.invoiceAmount + '；差额：' + (res.chargeAmount - res.invoiceAmount)
          })
        } else {
          this.yardScanInvoiceWarning = ''
        }
      } else {
        this.$refs.table2.clearSelected()
        this.$refs.table2.refresh(true)
      }
      this.selectedRows = []
    },
    changeFeeStatus() {
      if (this.mainTabKey === 'charge') {
        this.$refs.table1.clearSelected()
        this.$refs.table1.refresh(true)
      } else {
        this.$refs.table2.clearSelected()
        this.$refs.table2.refresh(true)
      }
      this.selectedRows = []
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.customerOps = []
      this.fetching = true
      getCustomer({
        department: null,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.customerOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.queryParam1.customer_name = value.key
      this.customerOps = []
      this.fetching = false
    },
    getInvoiceBuyerInfo(value) {
      this.invoiceBuyerFetchId += 1
      const fetchId = this.invoiceBuyerFetchId
      this.invoiceBuyerOps = []
      this.buyerfetching = true
      getInvoiceBuyer({
        buyer_name: value
      }).then(res => {
        if (fetchId !== this.invoiceBuyerFetchId) {
          return
        }
        this.invoiceBuyerOps = res
        this.buyerfetching = false
      })
    },
    changeInvoiceBuyerInfo(value) {
      this.invoiceBuyerInfo = value
      queryInvoiceBuyerById({ id: value.key }).then(res => {
        this.applyInvoiceForm.buyer_id = res.buyer_id
        this.applyInvoiceForm.buyer_name = res.buyer_name
        this.applyInvoiceForm.buyer_tax_no = res.buyer_tax_no
        this.applyInvoiceForm.buyer_address_phone = res.buyer_address_phone
        this.applyInvoiceForm.buyer_telphone = res.buyer_telphone
        this.applyInvoiceForm.buyer_bank_account = res.buyer_bank_account
        this.applyInvoiceForm.buyer_account = res.buyer_account
        this.applyInvoiceForm.is_allow_invoice = res.is_allow_invoice
      })
      this.invoiceBuyerOps = []
      this.buyerfetching = false
    },
    onDateChange(params, date, dateString) {
      if (date[0]) {
        this[params]['apply_from_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this[params]['apply_to_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this[params]['apply_from_time'] = null
        this[params]['apply_to_time'] = null
      }
    },
    onDateChange2(params, date, dateString) {
      if (date[0]) {
        this[params]['billing_from_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this[params]['billing_to_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this[params]['billing_from_time'] = null
        this[params]['billing_to_time'] = null
      }
    },
    onDateChange3(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.queryParam1['export_start_date'] = startDate
        this.queryParam1['export_end_date'] = endDate
      } else {
        this.queryParam1['export_start_date'] = null
        this.queryParam1['export_end_date'] = null
      }
    },
    onDateChange4(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.queryParam1['export_start_date'] = startDate
        this.queryParam1['export_end_date'] = endDate
      } else {
        this.queryParam1['export_start_date'] = null
        this.queryParam1['export_end_date'] = null
      }
    },
    onPayDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam1.pay_start = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam1.pay_end = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam1.pay_start = null
        this.queryParam1.pay_end = null
      }
    },
    handleTabChange(key) {
      if (key === 'charge') {
        this.$refs.table1.clearSelected()
      } else {
        this.$refs.table2.clearSelected()
      }
      this.selectedRows = []
      this.mainTabKey = key
    },
    // 打印开票单
    printInvoiceSheet(record, index) {
      this.currentData = record
      getInvoiceSheetBill({
        auditRecordId: record.id
      })
        .then(res => {
          this.invoiceData = res.list
          this.currentData['paymentInfo'] = res.paymentInfo
          record.is_print = 1
        })
        .finally(_ => {
          this.currentData['billType'] = `开票`
          this.printInvoiceSheetVisible = true
        })
    },
    // 打印付款单
    printPaymentSheet(record) {
      getFmsInfoDetail('business_audit', { id: record.id }).then(v => {
        const result = [];
        v.forEach(item => {
          const feeList = item.pay_fee_list;
          if (typeof feeList !== 'undefined') {
            feeList.forEach(fee => {
              result.push({
                order_num: fee.order_num,
                product_name: fee.product_name,
                fee_name: fee.fee_name,
                amount: fee.amount,
                fee_num: fee.fee_num
              });
            });
          }
        });
        this.printPayBillResult = result;
        this.printPayBillRecord = record;
        this.printPayBillRecord[`currency_name`] = `CNY`;
        this.printPayBillRecord['invoice_count'] = record.invoice_detail.length
        this.printPayBillModel = true;
      });
      // 根据 id 查询开票信息
      getInvoiceDataByAuditId({ id: record.id }).then(v => {
        this.invoiceList = v
      })
    },
    printDebitSheet(record) {
      this.currentData = record
      // 客服结算单
      getBizAuditPage('list_balance', { id: record.id })
        .then(res => {
          // 统计数据
          this.balanceStatic = {
            charge: '应收总计:' + res.charge_total,
            pay: '应付总计:' + res.pay_total,
            profit: '利润总计:' + res.profit
          }
          this.balanceData = res.balance_item_list
          this.balanceCurrency = res.currency
          this.balanceExchangeRate = res.exchange_rate
          this.currentData.balanceExchangeRate = res.exchange_rate
          this.currentData.balanceStatic = this.balanceStatic
          this.currentData.charge_total = res.charge_total
          this.currentData.pay_total = res.pay_total
          this.currentData.profit = res.profit
          this.currentData.last_add_fees = res.last_add_fees
        })
        .finally(() => {
          if (record.department === 7) {
            this.printCumsBillModel = true
          } else {
            // 国贸结算单
            this.printItmsBillModel = true
          }
        })
    },
    // 打开更新成本月份窗口
    openUpdateCostDate(record) {
      this.ymsOrderPassForm['id'] = record['id']
      this.ymsOrderPassForm['cost_begin_date'] = record['cost_begin_date']
      this.ymsOrderPassForm['cost_end_date'] = record['cost_end_date']
      this.updateCostDateVisible = true
    },
    // 更新成本月份窗口关闭
    updateCostDateCancel() {
      this.updateCostDateVisible = false
      this.updateCostDateLoading = false
      this.ymsOrderPassForm = {
        cost_begin_date: null,
        cost_end_date: null
      }
    },
    handleApplyInvoice(record) {
      this.applyInvoiceVisible = true
      this.applyInvoiceRecord = {
        record_no: record.record_no,
        order_type_name: record.order_type_name,
        sum_amount: record.sum_amount,
        tax_desc: record.tax_rate_info ? record.tax_rate_info.tax_desc : '',
        customer_name: record.invoice_info ? record.invoice_info.customer_name : record.customer,
        apply_remark: record.apply_remark
      }
      queryRecordInvoiceApplyInfo({ id: record.id }).then(v => {
          this.applyInvoiceForm.audit_id = record.id
          this.applyInvoiceForm.invoice_type = '0'
          // this.applyInvoiceForm.many_together = 'N'
          this.applyInvoiceForm.taxation_method = '0'
          this.applyInvoiceForm.price_tax_mark = '1'
          this.applyInvoiceForm.invoice_list_mark = '0'
          this.applyInvoiceForm.buyer_id = v.buyer_id
          this.applyInvoiceForm.buyer_name = v.buyer_name
          this.applyInvoiceForm.buyer_tax_no = v.buyer_tax_no
          this.applyInvoiceForm.buyer_address_phone = v.buyer_address_phone
          this.applyInvoiceForm.buyer_telphone = v.buyer_telphone
          this.applyInvoiceForm.buyer_bank_account = v.buyer_bank_account
          this.applyInvoiceForm.buyer_account = v.buyer_account
          this.applyInvoiceForm.remarks = v.invoice_remark
          this.applyInvoiceForm.many_together = v.many_together ? v.many_together : 'N'
          this.applyInvoiceForm.invoice_type_code = v.invoice_type_code
          this.applyInvoiceForm.is_allow_invoice = !v.is_allow_invoice ? 'Y' : v.is_allow_invoice
          console.info(this.applyInvoiceForm)
        if (v.tax_trays) {
          this.taxtrayList = v.tax_trays
          if (v.tax_trays.length === 1) {
            var taxTray = v.tax_trays[0]
            this.applyInvoiceForm.tax_tray_id = taxTray.id
            this.applyInvoiceForm.sale_company_name = taxTray.sale_company_name
            this.applyInvoiceForm.sale_company_tax_no = taxTray.sale_company_tax_no
            this.applyInvoiceForm.sale_company_address = taxTray.sale_company_address
            this.applyInvoiceForm.sale_company_mobile = taxTray.sale_company_mobile
            this.applyInvoiceForm.sale_company_address_mobile = taxTray.sale_company_address + taxTray.sale_company_mobile
            this.applyInvoiceForm.sale_company_bank = taxTray.sale_company_bank
            this.applyInvoiceForm.sale_company_account = taxTray.sale_company_account
            this.applyInvoiceForm.sale_company_bank_account = taxTray.sale_company_bank + taxTray.sale_company_account
            this.applyInvoiceForm.payee = taxTray.payee
            this.applyInvoiceForm.checker = taxTray.checker
            this.applyInvoiceForm.drawer = this.userInfo.name
          }
        }
        if (v.subject_dtos) {
          this.invoiceSubjectData = v.subject_dtos
          this.totalInvoiceAmount = 0
          this.invoiceSubjectData.forEach(s => {
            this.totalInvoiceAmount = this.totalInvoiceAmount + s.goods_total_price
            if (this.applyInvoiceForm.price_tax_mark === '0') {
              this.totalInvoiceAmount = this.totalInvoiceAmount + parseFloat(s.goods_total_tax)
            }
          })
        }
    })
    },
    changeSaleInvoiceInfo() {
      this.taxtrayList.forEach(t => {
        if (t.id === this.applyInvoiceForm.tax_tray_id) {
            this.applyInvoiceForm.tax_tray_id = t.id
            this.applyInvoiceForm.sale_company_name = t.sale_company_name
            this.applyInvoiceForm.sale_company_tax_no = t.sale_company_tax_no
            this.applyInvoiceForm.sale_company_address = t.sale_company_address
            this.applyInvoiceForm.sale_company_mobile = t.sale_company_mobile
            this.applyInvoiceForm.sale_company_address_mobile = t.sale_company_address + t.sale_company_mobile
            this.applyInvoiceForm.sale_company_bank = t.sale_company_bank
            this.applyInvoiceForm.sale_company_account = t.sale_company_account
            this.applyInvoiceForm.sale_company_bank_account = t.sale_company_bank + t.sale_company_account
            this.applyInvoiceForm.payee = t.payee
            this.applyInvoiceForm.checker = t.checker
            this.applyInvoiceForm.drawer = this.userInfo.name
        }
      })
    },
    applyInvoiceCancel() {
      this.applyInvoiceLoading = false
      this.applyInvoiceVisible = false
      this.invoiceBuyerInfo = undefined
      this.applyInvoiceForm = {
        invoice_type: '0',
        many_together: 'N',
        taxation_method: '0',
        price_tax_mark: '0',
        invoice_list_mark: '0',
        audit_id: null,
        invoice_type_code: null,
        tax_tray_id: null,
        buyer_id: null,
        buyer_name: null,
        buyer_tax_no: null,
        buyer_address_phone: null,
        buyer_telphone: null,
        buyer_bank_account: null,
        buyer_account: null,
        sale_company_name: null,
        sale_company_tax_no: null,
        sale_company_address_mobile: null,
        sale_company_bank_account: null,
        payee: null,
        checker: null,
        drawer: null,
        invoice_subject_data: []
      }
      this.invoiceSubjectData = []
      this.$refs.table1.refresh(false)
    },
    getInvoiceSubjectTableDate(data) {
      this.invoiceSubjectData = data
    },
    applyInvoiceOk() {
      this.applyInvoiceLoading = true
        this.$refs.applyInvoiceForm.validate(valid => {
        if (valid) {
          var auditId = this.applyInvoiceForm.audit_id
          var customerId = this.applyInvoiceForm.buyer_id
          this.applyInvoiceForm.invoice_subject_data = this.invoiceSubjectData
          submitApplyInvoiceForm(this.applyInvoiceForm).then(v => {
            this.$notification['success']({
              message: '提示',
              description: '开票成功'
            })
            this.applyInvoiceCancel();
            this.printInvoiceData = v
            this.printInvoiceAuditId = auditId
            this.printInvoiceVisible = true
            queryEmailsByCustomerId({ 'customer_id': customerId }).then(emails => {
              this.invoiceEmails = emails
              if (emails.length === 1) {
                this.emailForm.emails = []
                this.emailForm.emails.push(emails[0])
              }
            })
          //   const h = this.$createElement
          //   this.$confirm({
          //   title: '确认提示',
          //   content: h('div', [h('p', '开票成功'), h('p', '是否打印发票')]),
          //   onOk: () => {
          //     printInvoiceByAuditId({ 'audit_id': auditId }).then(_ => {
          //       this.$notification['success']({
          //         message: '提示',
          //         description: '发票打印成功'
          //       })
          //     })
          //   }
          // })
          }).catch(_ => {
            this.applyInvoiceLoading = false
          })
        } else {
          this.applyInvoiceLoading = false
        }
      })
    },
    // 更新成本月份窗口提交
    updateCostDateOk() {
      this.updateCostDateLoading = true
      this.$refs.ymsOrderPassForm.validate(valid => {
        if (valid) {
          updateCostDate(this.ymsOrderPassForm).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功.'
            })
          }).finally(() => {
            this.$refs.table2.clearSelected()
            this.$refs.table2.refresh(true)
            this.selectedRows = []
            this.updateCostDateCancel()
          })
        } else {
          this.updateCostDateLoading = false
        }
      })
    },
    costDateChange(value) {
      this.costDate = value
    },
    handlePanelChange(value, mode) {
      this.costDate = value
      this.costMode = [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]
      this.ymsOrderPassForm['cost_begin_date'] = value[0].startOf('day').format('YYYY-MM')
      this.ymsOrderPassForm['cost_end_date'] = value[1].endOf('day').format('YYYY-MM')
    },
    // 堆场付款审核通过
    YmsHandleOrderPass() {
      this.ymsOrderPassVisible = true
    },
    ymsOrderPassCancel() {
      this.ymsOrderPassVisible = false
      this.ymsOrderPassLoading = false
      // this.ymsOrderPassForm = {
      //   cost_begin_date: null,
      //   cost_end_date: null
      // }
    },
    ymsOrderPassSubmit() {
      this.ymsOrderPassLoading = true
      this.$refs.ymsOrderPassForm.validate(valid => {
        if (valid) {
          const reqParam = {
            'ids': this.selectedRowKeys,
            'cost_begin_date': this.ymsOrderPassForm.cost_begin_date,
            'cost_end_date': this.ymsOrderPassForm.cost_end_date
          }
          approveFmsInfo(reqParam).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功.'
            })
          }).finally(() => {
            this.$refs.table2.clearSelected()
            this.$refs.table2.refresh(true)
            this.ymsOrderPassCancel()
            this.selectedRows = []
          })
        } else {
          this.ymsOrderPassLoading = false
        }
      })
    },
    handleOrderPass() {
      checkBeforeApprove(this.selectedRowKeys).then(reslut => {
        const h = this.$createElement
        var warnMsg
        var iswarn = false
        for (let i = 0; i < reslut.length; i++) {
          if (reslut[i].profit < 0) {
            warnMsg = '流水号：' + reslut[i].record_no + '的总利润为' + reslut[i].profit + ';'

            iswarn = true;
          }
        }
        if (iswarn) {
          this.$confirm({
            title: '确认提示',
            content: h('div', [h('p', warnMsg), h('p', '确定要审核通过吗')]),
            onOk: () => {
              this.handleApproveFmsInfo(this.selectedRowKeys)
            }
          })
        } else {
          this.handleApproveFmsInfo(this.selectedRowKeys)
        }
      })
    },
    handleApproveFmsInfo(rowkeys) {
      // 审核通过
      const reqParam = {
        'ids': rowkeys
      }
      approveFmsInfo(reqParam).then(v => {
        this.$notification['success']({
          message: '提示',
          description: '操作成功.'
        })
        this.selectedRowKeys = []
        if (this.mainTabKey === 'charge') {
          this.$refs.table1.clearSelected()
          this.$refs.table1.refresh(true)
        } else {
          this.$refs.table2.clearSelected()
          this.$refs.table2.refresh(true)
        }
        this.selectedRows = []
      })
    },
    handleOrderReject(record) {
      this.currentData = record
      this.rejectForm.id = record.id
      this.rejectForm.deny_reason = ''
      this.rejectForm.fee_type = this.mainTabKey === 'charge' ? 1 : 2
      this.rejectFormVisible = true
    },
    handleRejectFormSubmit() {
      this.$refs.rejectForm.validate(valid => {
        if (valid) {
          denyFmsInfo(this.rejectForm).then(v => {
            this.selectedRowKeys = []
            this.rejectFormVisible = false
            this.$notification['success']({
              message: '提示',
              description: '操作成功.'
            })
            if (this.mainTabKey === 'charge') {
              this.$refs.table1.clearSelected()
              this.$refs.table1.refresh(true)
            } else {
              this.$refs.table2.clearSelected()
              this.$refs.table2.refresh(true)
            }
            this.selectedRows = []
          })
        }
      })
    },
    handlePrint(record) {
      this.currentData = record
      // 打印付款单
      getFmsInfoDetail('business_audit', { id: record.id }).then(v => {
        const data = v
        const result = []
        data.forEach(item => {
          const feeList = item.pay_fee_list
          if (typeof feeList !== 'undefined') {
            feeList.forEach(fee => {
              result.push(fee)
            })
          }
        })
        this.printPayBillResult = result
        this.printPayBillRecord = this.currentData
        this.printPayBillRecord['invoice_count'] = record.invoice_detail.length
        this.printPayBillModel = true
      })
      // 根据 id 查询开票信息
      getInvoiceDataByAuditId({ id: record.id }).then(v => {
        this.invoiceList = v
      })
    },
    // 打印开票通知单
    printInvoiceNotice(record) {
      this.currentData = record
      getMmsSheetBill({
        auditRecordId: record.id
      }).then(v => {
        this.detailData = v
        this.printInvoiceNoticeBillModel = true
        record.is_print = 1
      })
    },
    // 导出POS机发票结款清单
    exportWmsPosInvoiceSettlementSheet() {
      exportWmsPosInvoiceSettlementSheet({
        exportStartDate: this.queryParam1['export_start_date'],
        exportEndDate: this.queryParam1['export_end_date']
      }).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      }).finally(() => {
        this.popVisible = false;
      })
    },
    // 导出通联收入汇总表
    exportPayIncomeTotalSheet() {
      exportPayIncomeTotalSheet({
        exportStartDate: this.queryParam1['export_start_date'],
        exportEndDate: this.queryParam1['export_end_date']
      }).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      }).finally(() => {
        this.popVisible2 = false;
      })
    },
    exportWmsChargeTotalSheet() {
      exportChargeTotalSheet({
        exportStartDate: this.queryParam1['export_start_date'],
        exportEndDate: this.queryParam1['export_end_date']
      }).then(res => {
        if (res !== null) {
          const fileInfo = { fileName: res }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      }).finally(() => {
        this.popVisible3 = false;
      })
    },
    onDateSingleChange(date, dateString) {
      if (date) {
        const startDate = date.startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = date.endOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.queryParam1['export_start_date'] = startDate
        this.queryParam1['export_end_date'] = endDate
      } else {
        this.queryParam1['export_start_date'] = null
        this.queryParam1['export_end_date'] = null
      }
    },
    onDateRangeChange(date, dateString) {
      if (date[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.queryParam1['export_start_date'] = startDate
        this.queryParam1['export_end_date'] = endDate
      } else {
        this.queryParam1['export_start_date'] = null
        this.queryParam1['export_end_date'] = null
      }
    },
    ymsInvoiceDetailDateChange(date, dateStr) {
      if (date[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.queryParam1['invoice_start_date'] = startDate
        this.queryParam1['invoice_end_date'] = endDate
      } else {
        this.queryParam1['invoice_start_date'] = null
        this.queryParam1['invoice_end_date'] = null
      }
    },
    ymsMonthChargePayDateChange(date, dateStr) {
      if (dateStr) {
        this.queryParam1['export_month'] = dateStr
      } else {
        this.queryParam1['export_month'] = null
      }
    },
    cancelprintInvoiceRecord() {
      this.printInvoiceVisible = false
      this.printInvoiceLoading = false;
      this.printInvoiceData = []
      this.printInvoiceAuditId = null
      this.$refs.table1.refresh(false)
      this.emailForm = {
        invoice_nos: [],
        emails: []
      }
    },
    handlePrintInvoiceSubmit() {
      this.printInvoiceLoading = true;
      var isEInvoice = false
      this.printInvoiceData.forEach(item => {
        if (item.einvoice_url) {
          isEInvoice = true;
          this.handlePrintOneInvoice(item)
        }
      })
      if (!isEInvoice) {
        printInvoiceByAuditId({ 'audit_id': this.printInvoiceAuditId }).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '所有发票打印成功'
          })
        }).finally(() => {
          this.printInvoiceLoading = false
        })
      } else {
        this.printInvoiceLoading = false;
      }
    },
    handlePrintOneInvoice(record) {
      console.info(record.einvoice_url)
      if (record.einvoice_url) {
        getEInvoiceAndSendEmail({ 'invoice_no': record.invoice_no }).then(v => {
          if (v) {
            window.open(v, true);
          }
        })
      } else {
        this.printInvoiceLoading = true;
        printInvoice({ 'invoice_no': record.invoice_no }).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '发票打印成功'
          })
        }).finally(() => {
          // this.printInvoiceLoading = false;
        })
      }
    },
    handleSendEmailSubmit() {
      this.printInvoiceData.forEach(v => {
        this.emailForm.invoice_nos.push(v.invoice_no)
      });
      this.sendEmailDisabled = true
      togetherSendEmail(this.emailForm)
        .then(_ => {
          this.emailConfirmLoading = false
          this.emailFormVisible = false
          this.$notification['success']({
            message: '提示',
            description: '发送电子发票邮件成功'
          })
        })
        .catch(_ => {
          this.sendEmailDisabled = false
        }).finally(() => {
          this.sendEmailDisabled = false
        })
    },
    handleSearchEmails(value) {
      if (value && value.length > 2) {
        likeSearchEmail({ 'email': value }).then(v => {
          this.invoiceEmails = v
        })
      }
    },
    handleChangeEmail(value) {
      console.info(value)
      this.emailForm.emails = []
      value.forEach(element => {
        this.emailForm.emails.push(element)
      });
      console.info(this.emailForm.emails)
    }
  }
}
</script>

<style>
.row-footer .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
  display: none;
}
.action-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 0 20px;
}
.action-button > button {
  margin: 0 10px;
}
.selectContextOpen {
  margin: 0;
}
.selectContextClose {
  margin: 0;
  height: 320px;
  overflow: auto;
}
.margin-left15 {
  margin-left: 15px;
}
/** 堆场登记开票扫码框隐藏样式 */
.scan-input {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  opacity: 0;
}
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
</style>
